import { createActions, createReducer } from 'reduxsauce';

/* Types & Actions Creators */
export const { Types, Creators } = createActions({
  setUser: ['user'],
});

/* Initial State */
const INITIAL_STATE = {};

const setUser = (state, { user }) => {
  return { ...state, ...user };
};

/* Reducers to types */
const reducer = createReducer(INITIAL_STATE, {
  [Types.SET_USER]: setUser,
});

export default reducer;
