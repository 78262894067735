import { useEffect, useRef } from 'react';

import FadeIn from 'react-fade-in';
import { useDispatch, useSelector } from 'react-redux';

import LoginBackground from '~/assets/img/login-background.png';
import { ReactComponent as Svg } from '~/assets/svg/undraw_Group_chat_unwm.svg';
import Button from '~/components/Button';
import Checkbox from '~/components/Material/Checkbox';
import Input from '~/components/Material/Input';
import AuthLayout from '~/layouts/Auth';
import { Creators as AuthActions } from '~/store/ducks/auth';
import yupValidate from '~/utils/yupValidate';
import { loginSchema } from '~/validators/login.schemas';

import {
  Container,
  FormContainer,
  Title,
  Illustration,
  Controls,
  ResetPasswordContainer,
  ResetPasswordButton,
} from './styles';

const Login = () => {
  const formRef = useRef(null);
  const buttonRef = useRef(null);

  const dispatch = useDispatch();

  const { loading } = useSelector((state) => state.auth);

  useEffect(() => {
    if (loading) {
      buttonRef.current.start();
    } else {
      buttonRef.current.reset();
    }
  }, [loading]);

  const handleSubmit = async (formData) => {
    // Limpa os erros
    formRef.current.setErrors({});

    // Valida os campos
    const { success, data, errors } = await yupValidate(loginSchema, {
      ...formData,
    });

    if (success) {
      const { login, password } = data;

      dispatch(AuthActions.authLoginRequest(login, password));
    } else {
      formRef.current.setErrors(errors);
    }
  };

  return (
    <AuthLayout>
      <Container src={LoginBackground}>
        <FormContainer ref={formRef} onSubmit={handleSubmit}>
          <FadeIn>
            <Title>Login</Title>
            <Input name="login" label="Usuário" />
            <Input type="password" name="password" label="Senha" />
            <Controls>
              <Checkbox name="remember" label="Manter sessão" defaultValue />
            </Controls>
            <Button ref={buttonRef} type="submit" label="Entrar" fullWidth />
            <ResetPasswordContainer>
              <ResetPasswordButton to="/esqueci-minha-senha">
                esqueci minha senha
              </ResetPasswordButton>
            </ResetPasswordContainer>
          </FadeIn>
        </FormContainer>
        <Illustration>
          <Svg />
        </Illustration>
      </Container>
    </AuthLayout>
  );
};

export default Login;
