import PropTypes from 'prop-types';

import EditButton from './EditButton';
import ViewButton from './ViewButton';

const TableActions = ({ onEdit, onView }) => {
  return (
    <>
      {onEdit && <EditButton onClick={onEdit} />}
      {onView && <ViewButton onClick={onView} />}
    </>
  );
};

TableActions.defaultProps = {
  onEdit: null,
  onView: null,
};

TableActions.propTypes = {
  onEdit: PropTypes.func,
  onView: PropTypes.func,
};

export default TableActions;
