import PropTypes from 'prop-types';

import { Container } from './styles';

const SubTitle = ({ label }) => {
  return <Container>{label}</Container>;
};

SubTitle.propTypes = {
  label: PropTypes.string.isRequired,
};

export default SubTitle;
