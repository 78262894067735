import yup from '~/libs/yup';

export const storeSchema = yup.object({
  type: yup.string().default('C').label('Tipo pessoa'),
  name: yup.string().required().label('Nome'),
  phone: yup.string().required().label('Telefone'),
  whatsapp: yup.bool().label('WhatsApp').default(false),
  email: yup.string().email().required().label('E-mail'),
  personPhysic: yup.object().when('type', {
    is: 'F',
    then: yup
      .object()
      .shape({
        cpf: yup.string().required().label('CPF'),
        rg: yup.string().required().label('RG'),
      })
      .required(),
  }),
  personJuristic: yup.object().when('type', {
    is: 'J',
    then: yup
      .object()
      .shape({
        cnpj: yup.string().required().label('CNPJ'),
        state_registration: yup.string().label('Inscrição estadual'),
        city_registration: yup.string().label('Inscrição municipal'),
      })
      .required(),
  }),
  address: yup.string().label('Rua'),
  number: yup.string().label('Número'),
  neighborhood: yup.string().label('Bairro'),
  city: yup.string().label('Cidade'),
  state: yup.string().label('Estado'),
});
