import { useRef, useEffect } from 'react';

import { useField } from '@unform/core';
import * as FaIcons from 'react-icons/fa';

import icons from './icons';
import { Container, IconContainer, Icon } from './styles';

const GridIcons = () => {
  const radiosRef = useRef([]);

  const { fieldName, registerField, defaultValue } = useField('icon_name');

  useEffect(() => {
    registerField({
      name: fieldName,
      path: 'value',
      ref: radiosRef.current,
      getValue(refs) {
        const checked = refs.find((ref) => ref.checked);

        return checked ? checked.value : null;
      },
      setValue(refs, value) {
        const item = refs.find((ref) => ref.value === value);

        if (item) {
          item.checked = true;
        }
      },
    });
  }, [fieldName, registerField]);

  useEffect(() => {
    if (radiosRef.current && defaultValue !== undefined) {
      const radioRef = radiosRef.current.find(
        (ref) => ref.value.toString() === defaultValue.toString()
      );

      if (radioRef) {
        radioRef.scrollIntoView();
        radioRef.checked = true;
      }
    }
  }, [defaultValue]);

  return (
    <Container>
      {icons.map((icon, index) => {
        const FaIcon = FaIcons[icon];
        return (
          <IconContainer key={icon}>
            <input
              ref={(elRef) => {
                radiosRef.current[index] = elRef;
              }}
              type="radio"
              name="icon_name"
              value={icon}
              defaultChecked={icon === defaultValue}
            />
            <Icon>
              <FaIcon />
            </Icon>
          </IconContainer>
        );
      })}
    </Container>
  );
};

export default GridIcons;
