import { combineReducers } from 'redux';

import auth from './auth';
import lodgingCoverId from './lodgingCoverId';
import lodgingTotalImages from './lodgingTotalImages';
import theme from './theme';
import user from './user';

const reducers = combineReducers({
  auth,
  lodgingTotalImages,
  lodgingCoverId,
  theme,
  user,
});

export default reducers;
