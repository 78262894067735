import yup from '~/libs/yup';

export const createSchema = yup.object({
  is_search: yup.bool().required(),
  client_id: yup
    .number()
    .when('is_search', { is: true, then: yup.number().required() })
    .nullable()
    .label('Cliente'),
  nome: yup
    .string()
    .when('is_search', { is: false, then: yup.string().required() })
    .nullable()
    .label('Nome'),
  telefone: yup
    .string()
    .when('is_search', { is: false, then: yup.string().required() })
    .nullable()
    .label('Telefone'),
  email: yup
    .string()
    .email()
    .when('is_search', { is: false, then: yup.string().required() })
    .nullable()
    .label('email'),
  proposito: yup.string().required().label('Propósito'),
});
