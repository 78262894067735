import { useCallback, useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';

import PrivateLayout from '~/layouts/Private';
import api from '~/services/api';
import masks from '~/utils/masks';

import Board from './Board';
import DetailsContext from './DetailsContext';
import List from './List';
import { Container } from './styles';

const LodgingDetail = () => {
  const { id } = useParams();

  const [data, setData] = useState([]);

  const groupDetails = (array) => {
    const formattedArray = array.reduce((groups, { name, ...rest }) => {
      const firstLetter = masks.normalize(name.charAt(0)).toUpperCase();

      const hasGroup = groups.findIndex(({ letter }) => letter === firstLetter);

      if (hasGroup > -1) {
        groups[hasGroup].details.push({ name, ...rest });
      } else {
        groups.push({
          letter: firstLetter,
          details: [{ name, ...rest }],
        });
      }

      return groups;
    }, []);

    const sortedArray = formattedArray.sort((a, b) =>
      a.letter < b.letter ? -1 : 1
    );

    return sortedArray;
  };

  const compareDetails = (paramsDetails, lodgingDetails) => {
    if (lodgingDetails.length === 0)
      return paramsDetails.map((paramDetail) => ({
        ...paramDetail,
        lodging: null,
      }));

    return paramsDetails.map((paramDetail) => {
      const detail = lodgingDetails.find(
        (lodgingDetail) => lodgingDetail.detail_id === paramDetail.id
      );

      return {
        ...paramDetail,
        lodging: detail || null,
      };
    });
  };

  const getParamsDetails = useCallback(async () => {
    try {
      const response = await api.get(`/details?orderBy=name&active=true`);
      if (response.status === 200) {
        // Formatando os dados

        return response.data;
      }
      return [];
    } catch (error) {
      return [];
    }
  }, []);

  const getLodgingDetails = useCallback(async () => {
    try {
      const response = await api.get(`/lodgings/${id}/details?active=true`);

      if (response.status === 200) {
        // Formatando os dados

        return response.data;
      }
      return [];
    } catch (error) {
      return [];
    }
  }, [id]);

  useEffect(() => {
    const getDetails = async () => {
      const paramsDetails = await getParamsDetails();
      const lodgingDetails = await getLodgingDetails();

      const detailsToFormat = compareDetails(paramsDetails, lodgingDetails);
      const formattedDetails = groupDetails(detailsToFormat);

      setData(formattedDetails);
    };

    getDetails();
  }, [getParamsDetails, getLodgingDetails]);

  const addDetailToLodging = useCallback(
    (detailId) => {
      setData((stateData) => {
        const updatedState = stateData.map(({ letter, details }) => {
          const detailsUpdated = details.map((detail) =>
            detail.id === detailId
              ? {
                  ...detail,
                  lodging: {
                    id: 0,
                    lodging_id: Number(id),
                    detail_id: detailId,
                    value: '',
                  },
                }
              : detail
          );

          return {
            letter,
            details: detailsUpdated,
          };
        });

        return updatedState;
      });
    },
    [id]
  );

  const removeDetailFromLodging = useCallback((detailId) => {
    setData((stateData) => {
      const updatedState = stateData.map(({ letter, details }) => {
        const detailsUpdated = details.map((detail) =>
          detail.id === detailId
            ? {
                ...detail,
                lodging: null,
              }
            : detail
        );

        return {
          letter,
          details: detailsUpdated,
        };
      });

      return updatedState;
    });
  }, []);

  const updatePivotDetail = useCallback(
    (detailId, pivotId) => {
      setData((stateData) => {
        const updatedState = stateData.map(({ letter, details }) => {
          const detailsUpdated = details.map((detail) =>
            detail.id === detailId
              ? {
                  ...detail,
                  lodging: {
                    id: pivotId,
                    lodging_id: Number(id),
                    detail_id: detailId,
                    value: '',
                  },
                }
              : detail
          );

          return {
            letter,
            details: detailsUpdated,
          };
        });

        return updatedState;
      });
    },
    [id]
  );

  return (
    <PrivateLayout>
      <Container>
        <DetailsContext.Provider
          value={{
            data,
            addDetailToLodging,
            updatePivotDetail,
            removeDetailFromLodging,
          }}
        >
          <List />
          <Board />
        </DetailsContext.Provider>
      </Container>
    </PrivateLayout>
  );
};

export default LodgingDetail;
