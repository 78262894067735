import yup from '~/libs/yup';

export const createSchema = yup.object({
  hospedagem: yup.string().label('Hospedagem'),
  checkin: yup.date().required().label('Checkin'),
  checkout: yup.date().required().label('Checkout'),
  adultos: yup.number().required().nullable().min(1).label('Adultos'),
  criancas: yup.number().required().nullable().label('Crianças'),
  infantil: yup.number().required().nullable().label('Infantil'),
  mensagem: yup.string().label('Mensagem'),
});
