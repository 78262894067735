import PrivateLayout from '~/layouts/Private';

import { Container } from './styles';

const Settings = () => {
  return (
    <PrivateLayout>
      <Container>Preferencias do sistema</Container>;
    </PrivateLayout>
  );
};

export default Settings;
