import {
  IoIosColorPalette,
  IoIosFunnel,
  IoIosList,
  IoIosSettings,
} from 'react-icons/io';

export default [
  {
    label: 'Hospedagens',
    to: '/hospedagens',
    enabled: true,
    sub_menus: [
      {
        label: 'Hospedagens',
        to: '/hospedagens',
        icon: <IoIosList />,
      },
    ],
  },
  {
    label: 'Pessoas',
    to: '/pessoas',
    enabled: true,
    sub_menus: [
      {
        label: 'Pessoas',
        to: '/pessoas',
        icon: <IoIosList />,
      },
    ],
  },
  {
    label: 'Usuários',
    to: '/usuarios',
    enabled: true,
    sub_menus: [
      {
        label: 'Usuários',
        to: '/usuarios',
        icon: <IoIosList />,
      },
    ],
  },
  {
    label: 'Atendimento',
    to: '/atendimento/leads',
    enabled: true,
    sub_menus: [
      {
        label: 'Leads',
        to: '/atendimento/leads',
        icon: <IoIosFunnel />,
      },
    ],
  },
  {
    label: 'Financeiro',
    to: '/financeiro',
    enabled: false,
    sub_menus: [],
  },
  {
    label: 'Configurações',
    to: '/configuracoes',
    enabled: true,
    sub_menus: [
      {
        label: 'Configurações',
        to: '/configuracoes',
        icon: <IoIosSettings />,
      },
      {
        label: 'Detalhes',
        to: '/configuracoes/hospedagens/detalhes',
        icon: <IoIosColorPalette />,
      },
    ],
  },
];
