import { call, put } from 'redux-saga/effects';

import api from '~/services/api';
import { Creators as AuthActions } from '~/store/ducks/auth';
import { Creators as UserActions } from '~/store/ducks/user';

export function* authLogin({ login, password }) {
  try {
    const response = yield call(api.post, '/sessions', {
      login,
      password,
    });

    const { authentication, user } = response.data;
    const { token } = authentication;

    api.defaults.headers.Authorization = `Bearer ${token}`;

    yield put(AuthActions.authLoginSuccess(token));
    yield put(UserActions.setUser(user));
  } catch (error) {
    yield put(AuthActions.authLoginFailure());
  }
}

export function* authSetToken({ payload }) {
  if (!payload) {
    return;
  }

  const { token } = payload.auth;

  if (token) {
    api.defaults.headers.Authorization = `Bearer ${token}`;
  } else {
    yield put(AuthActions.authLogout());
  }
}
