import { useCallback, useEffect, useRef, useState } from 'react';

import { toast } from 'react-toastify';

import Actions from '~/components/Actions';
import Button from '~/components/Button';
import Header from '~/components/Header';
import PrivateLayout from '~/layouts/Private';
import api from '~/services/api';

import CardLodging from './CardLodging';
import Form from './Form';
import { Container, Lodgings } from './styles';

const List = () => {
  const formRef = useRef();
  const [lodgings, setLodgings] = useState({
    data: [],
    total: 0,
  });

  const getLodgings = useCallback(async () => {
    try {
      const { data } = await api.get(
        `lodgings?active=true&orderBy=updated_at DESC`
      );

      setLodgings({ data, total: data.length });
    } catch (error) {
      toast.error('Erro ao buscar detalhes...');
    }
  }, []);

  useEffect(() => {
    getLodgings();
  }, [getLodgings]);

  const handleClickOpenForm = () =>
    formRef.current.setData({
      name: '',
    });

  const handleClickEdit = (data) => formRef.current.setData(data);

  const handleOnClose = (reload) => {
    if (reload) {
      getLodgings(1);
    }
  };

  return (
    <PrivateLayout>
      <Container>
        <Header title="Hospedagens" total={lodgings.total} />
        <Actions align="flex-end">
          <Button label="Nova hospedagem" onClick={handleClickOpenForm} />
        </Actions>
        <Form ref={formRef} onClose={handleOnClose} />
        <Lodgings>
          {lodgings.data.map(({ id, ...restLodging }) => (
            <CardLodging
              key={id}
              data={{ id, ...restLodging }}
              onEdit={handleClickEdit}
            />
          ))}
        </Lodgings>
      </Container>
    </PrivateLayout>
  );
};

export default List;
