import styled, { css } from 'styled-components';

import { lighten } from 'polished';
import { Link as RouterLink } from 'react-router-dom';

export const Container = styled.div`
  display: flex;
  align-items: center;
`;

export const Link = styled(RouterLink)`
  cursor: pointer;
  user-select: none;

  display: flex;
  align-items: center;

  height: 100%;

  font-size: 14px;
  font-weight: 500;
  color: #48484a;

  &:not(:last-of-type) {
    margin-right: 15px;
  }

  ${({ active, disabled }) =>
    active === 'active' &&
    !disabled &&
    css`
      color: #007aff;
      border-bottom: 2px solid #007aff;
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
      cursor: not-allowed;
      color: #aeaeb2;
    `}
`;

export const Label = styled.span`
  padding: 7px;
  border-radius: 4px;
  transition: background-color 200ms linear, color 200ms linear;

  :hover {
    color: #007aff;
    background-color: ${lighten(0.4, '#007aff')};
  }
`;
