import PropTypes from 'prop-types';

import SideMenu from './SideMenu';
import { Container, Content } from './styles';
import TopMenu from './TopMenu';

const Private = ({ children }) => {
  return (
    <Container>
      <TopMenu />
      <SideMenu />
      <Content>{children}</Content>
    </Container>
  );
};

Private.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object,
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]).isRequired,
};

export default Private;
