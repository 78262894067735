import { useContext, useEffect, useState } from 'react';

import DetailsContext from '../DetailsContext';
import Header from './Header';
import List from './List';
import { Container } from './styles';

const Board = () => {
  const { data } = useContext(DetailsContext);

  const [detailsOfLodging, setDetailsOfLodging] = useState([]);

  useEffect(() => {
    const filterDetails = () => {
      const toRender = [];

      data.map(({ details }) =>
        details.map((detail) => {
          if (detail.lodging) {
            toRender.push(detail);
          }

          return detail;
        })
      );

      setDetailsOfLodging(toRender);
    };

    if (data.length > 0) filterDetails();
  }, [data]);

  return (
    <Container>
      <Header total={detailsOfLodging.length} />
      <List data={detailsOfLodging} />
    </Container>
  );
};

export default Board;
