import styled, { css } from 'styled-components';

export const Container = styled.div`
  position: absolute;

  display: flex;
  flex-direction: column;

  left: 15px;
  top: 50%;

  transform: translateY(-50%);
`;

export const Letter = styled.button`
  border: none;

  height: 22px;
  width: 22px;

  border-radius: 50%;

  font-size: 14px;

  transition: color 200ms linear, background-color 200ms linear;

  &:hover:not(:disabled) {
    background-color: #007aff20;
  }

  ${({ active, disabled }) => {
    if (active) {
      return css`
        color: #ffffff;
        background-color: #007aff;
      `;
    }

    if (!disabled) {
      return css`
        color: #007aff;
        background-color: transparent;
      `;
    }

    return css`
      color: #aeaeb2;
      background-color: transparent;
    `;
  }};
`;
