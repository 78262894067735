import { useRef } from 'react';

import PrivateLayout from '~/layouts/Private';

import DraggableBoard from './DraggableBoard';
import Header from './Header';
import ImportFile from './ImportFile';
import { Container, Actions } from './styles';

const LodgingImage = () => {
  const draggableBoardRef = useRef();

  const handleChange = () => draggableBoardRef.current.getImages();
  const getTotalImages = () => {
    if (draggableBoardRef.current) return draggableBoardRef.current.totalImages;

    return 0;
  };

  return (
    <PrivateLayout>
      <Container>
        <Header />
        <Actions align="flex-end">
          <ImportFile onChange={handleChange} totalImages={getTotalImages()} />
        </Actions>
        <DraggableBoard ref={draggableBoardRef} />
      </Container>
    </PrivateLayout>
  );
};

export default LodgingImage;
