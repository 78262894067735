import { createActions, createReducer } from 'reduxsauce';

/* Types & Actions Creators */
export const { Types, Creators } = createActions({
  authLoginFailure: [],
  authLoginSuccess: ['token'],
  authLoginRequest: ['login', 'password'],
  authLogout: [],
});

/* Initial State */
const INITIAL_STATE = {
  token: null,
  isAuthenticated: false,
  loading: false,
};

/* Reducers */
const authLoginFailure = () => INITIAL_STATE;

const authLoginRequest = () => ({ ...INITIAL_STATE, loading: true });

const authLoginSuccess = (state, { token }) => ({
  isAuthenticated: true,
  loading: false,
  token,
});

const authLogout = () => INITIAL_STATE;

/* Reducers to types */
const reducer = createReducer(INITIAL_STATE, {
  [Types.AUTH_LOGIN_FAILURE]: authLoginFailure,
  [Types.AUTH_LOGIN_REQUEST]: authLoginRequest,
  [Types.AUTH_LOGIN_SUCCESS]: authLoginSuccess,
  [Types.AUTH_LOGOUT]: authLogout,
});

export default reducer;
