import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  height: 100%;

  padding: 30px 30px 30px 45px;
`;

export const Header = styled.div`
  margin-bottom: 30px;
`;

export const Title = styled.div`
  font-size: 20px;
  font-weight: 500;
  color: #2c2c2e;
`;

export const Content = styled.div`
  height: 100%;

  padding-right: 10px;

  overflow-y: scroll;
`;

export const Group = styled.div``;
