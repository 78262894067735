import styled from 'styled-components';

import devices, { sizes } from '~/styles/configs/devices';

export const Container = styled.td`
  font-weight: 500;
  font-size: 14px;
  text-align: ${({ align }) => align};

  padding: 10px;

  @media ${`${devices.mobileS} and (max-width: ${sizes.tablet})`} {
    display: block;

    &:before {
      content: attr(data-label);

      float: left;

      font-weight: 800;
      color: #1c1c1e;

      margin-right: 10px;
    }
  }
`;
