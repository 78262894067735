import styled from 'styled-components';

import devices from '~/styles/configs/devices';

export const Container = styled.div`
  height: 100%;
  padding: 30px;
  overflow-y: scroll;
`;

export const Lodgings = styled.div`
  display: grid;
  grid-gap: 30px;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr 1fr;

  @media ${devices.laptop} {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media ${devices.laptopL} {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  @media ${devices.desktop} {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }
`;

export const Title = styled.div`
  font-size: 28px;
  font-weight: 600;
`;

export const Head = styled.div`
  padding: 30px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`;
