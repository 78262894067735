import { useCallback, useEffect, useState } from 'react';

import PropTypes from 'prop-types';

import { Container, Letter } from './styles';

const AlphabeticFilter = ({ availableLetters, onChange }) => {
  const alphabet = [
    'A',
    'B',
    'C',
    'D',
    'E',
    'F',
    'G',
    'H',
    'I',
    'J',
    'K',
    'L',
    'M',
    'N',
    'O',
    'P',
    'Q',
    'R',
    'S',
    'T',
    'U',
    'V',
    'W',
    'X',
    'Y',
    'Z',
  ];

  const [selectedValue, setSelectedValue] = useState();

  useEffect(() => {
    if (availableLetters.length > 0) {
      setSelectedValue(availableLetters[0]);
    }
  }, [availableLetters]);

  useEffect(() => {
    onChange(selectedValue);
  }, [selectedValue, onChange]);

  const handleClick = useCallback((event) => {
    const { value } = event.target;
    setSelectedValue(value);
  }, []);

  return (
    <Container>
      {alphabet.map((letter) => (
        <Letter
          key={letter}
          type="button"
          value={letter}
          onClick={handleClick}
          active={selectedValue === letter}
          disabled={!availableLetters.includes(letter)}
        >
          {letter}
        </Letter>
      ))}
    </Container>
  );
};

AlphabeticFilter.propTypes = {
  availableLetters: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default AlphabeticFilter;
