import styled from 'styled-components';

export const Container = styled.div`
  margin-bottom: 15px;
`;

export const Title = styled.div`
  font-size: 24px;
  font-weight: 500;
  color: #1c1c1e;
`;

export const Total = styled.div`
  font-size: 16px;
  font-weight: 500;
  color: #8e8e93;
`;
