import { useLocation } from 'react-router-dom';

import menus from '~/utils/menus';

import { Container, Link, Label } from './styles';

const Navigation = () => {
  const { pathname } = useLocation();

  const isActive = (path) => (pathname.indexOf(path) === 0 ? 'active' : '');

  return (
    <Container>
      {menus.map(({ to, label, enabled }) => (
        <Link key={label} to={to} active={isActive(to)} disabled={!enabled}>
          <Label>{label}</Label>
        </Link>
      ))}
    </Container>
  );
};

export default Navigation;
