import styled, { css } from 'styled-components';

export const Container = styled.div`
  position: fixed;
  top: 0;
  right: 0;

  overflow-y: scroll;

  height: 100vh;
  width: 100vw;
  max-width: 600px;

  background-color: #ffffff;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);

  ${({ open }) => {
    if (open) {
      return css`
        transform: translateX(0);
        transition: transform 200ms ease-in;
      `;
    }

    return css`
      transform: translateX(100%);
      transition: transform 250ms ease-out;
    `;
  }}

  z-index: 101;
`;

export const CloseButton = styled.button`
  border: none;
  background-color: transparent;

  position: absolute;
  top: 0;
  right: 0;

  font-size: 40px;
  color: #8e8e93;

  svg {
    pointer-events: none;
  }
`;

export const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;

  height: 100vh;
  width: 100vw;

  z-index: 100;

  ${({ open }) => {
    if (open) {
      return css`
        cursor: pointer;
        background-color: rgba(0, 0, 0, 0.3);
        transition: background-color 200ms ease-in;
      `;
    }

    return css`
      pointer-events: none;
      background-color: rgba(0, 0, 0, 0);
      transition: background-color 250ms ease-out;
    `;
  }}
`;
