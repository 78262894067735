import PropTypes from 'prop-types';

import Button from '~/components/Button';

import { Container, Content, Label } from './styles';

const DangerZone = ({ message, onClick }) => {
  return (
    <Container>
      <Content>
        <Label>{message}</Label>
        <Button
          type="button"
          label="deletar"
          variant="danger"
          onClick={onClick}
        />
      </Content>
    </Container>
  );
};

DangerZone.propTypes = {
  message: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default DangerZone;
