import { IoIosClose } from 'react-icons/io';
import { ToastContainer } from 'react-toastify';

const Toast = () => {
  return (
    <ToastContainer
      position="top-right"
      className="toast-container"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnVisibilityChange
      draggable
      pauseOnHover
      closeButton={
        <>
          <IoIosClose size={24} />
        </>
      }
    />
  );
};

export default Toast;
