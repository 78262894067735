import { useRef } from 'react';

import PropTypes from 'prop-types';

import Button from '~/components/Button';

import { Container, Content } from './styles';

const FixedControl = ({ buttonRef, buttonLabel }) => {
  const ref = useRef();

  return (
    <Container>
      <Content>
        <Button
          ref={buttonRef || ref}
          type="submit"
          label={buttonLabel}
          variant="success"
        />
      </Content>
    </Container>
  );
};

FixedControl.defaultProps = {
  buttonRef: false,
  buttonLabel: 'Salvar',
};

FixedControl.propTypes = {
  buttonRef: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  buttonLabel: PropTypes.string,
};

export default FixedControl;
