import PropTypes from 'prop-types';
import RcPagination from 'rc-pagination';
import locale from 'rc-pagination/es/locale/pt_BR';
import { IoIosMore, IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';

import { Container } from './styles';

const Pagination = ({ page, onChange, lastPage, perPage, total, position }) => {
  // Só mostra a paginação caso o número total de páginas for maior que 1
  if (lastPage < 2) return null;

  return (
    <Container position={position}>
      <RcPagination
        current={page}
        total={Number(total)}
        pageSize={perPage}
        onChange={onChange}
        locale={locale}
        prevIcon={<IoIosArrowBack />}
        nextIcon={<IoIosArrowForward />}
        jumpPrevIcon={<IoIosMore />}
        jumpNextIcon={<IoIosMore />}
      />
    </Container>
  );
};

Pagination.defaultProps = {
  position: 'flex-end',
};

Pagination.propTypes = {
  page: PropTypes.number.isRequired,
  perPage: PropTypes.number.isRequired,
  total: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  lastPage: PropTypes.number.isRequired,
  position: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

export default Pagination;
