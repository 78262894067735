import PropTypes from 'prop-types';

import { Container } from './styles';

const Auth = ({ children }) => {
  return <Container>{children}</Container>;
};

Auth.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object,
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]).isRequired,
};

export default Auth;
