import { useState, useEffect } from 'react';

import { useLocation } from 'react-router-dom';

import menus from '~/utils/menus';

import Link from './Link';
import { Container, Title, ListLinks } from './styles';

const SideMenu = () => {
  const { pathname } = useLocation();
  const [menu, setMenu] = useState({
    sub_menus: [],
  });

  useEffect(() => {
    const result = menus.find(({ to }) => pathname.indexOf(to) === 0);

    if (result) {
      setMenu(result);
    } else {
      setMenu({
        sub_menus: [],
      });
    }
  }, [pathname]);

  return (
    <Container>
      <Title>{menu.label}</Title>
      <ListLinks>
        {menu.sub_menus.map(({ to, label, icon }) => (
          <Link
            key={to}
            to={to}
            label={label}
            icon={icon}
            active={to === pathname ? 'active' : ''}
          />
        ))}
      </ListLinks>
    </Container>
  );
};

export default SideMenu;
