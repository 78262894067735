import {
  cloneElement,
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import PropTypes from 'prop-types';
import { CgSpinner } from 'react-icons/cg';
import { MdDone, MdError } from 'react-icons/md';

import { Container, IconBox, Label } from './styles';

const Button = forwardRef(
  ({ fullWidth, icon, label, onClick, type, variant }, ref) => {
    const buttonRef = useRef(null);
    const timerRef = useRef(null);

    const [status, setStatus] = useState(null);
    const [progress, setProgress] = useState(0);

    const reset = () => {
      buttonRef.current.disabled = false;
      setProgress(0);
      setStatus(null);
      clearInterval(timerRef.current);
    };

    const start = () => {
      setProgress(0);
      buttonRef.current.disabled = true;

      /**
       * Gera valores aleatórios entre 0 e 20 até que
       * fique próximo de 80 então retorna 80
       */
      timerRef.current = setInterval(() => {
        setProgress((value) => {
          const newValue = value + Math.floor(Math.random() * 20);

          if (newValue < 80) {
            return newValue;
          }

          clearInterval(timerRef.current);
          return 80;
        });
      }, 300);
    };

    const finish = (options = {}) => {
      setProgress(100);
      clearInterval(timerRef.current);

      if (options?.autoReset) {
        setTimeout(() => {
          reset();
        }, 1000 * (options?.timeReset || 2));
      }

      if (options?.type) {
        setStatus(options.type);
      }

      if (options.callback) {
        setTimeout(() => {
          options.callback();
        }, 1000);
      }
    };

    useImperativeHandle(
      ref,
      () => ({
        finish,
        reset,
        start,
      }),
      []
    );

    const getIcon = () => {
      if (progress === 100 && status === 'success') {
        return (
          <IconBox>
            <MdDone />
          </IconBox>
        );
      }

      if (progress === 100 && status === 'error') {
        return (
          <IconBox>
            <MdError />
          </IconBox>
        );
      }

      if (progress > 0) {
        return (
          <IconBox>
            <CgSpinner />
          </IconBox>
        );
      }

      if (icon) {
        return <IconBox>{cloneElement(icon)}</IconBox>;
      }

      return null;
    };

    return (
      <Container
        ref={buttonRef}
        type={type}
        fullWidth={fullWidth}
        onClick={onClick}
        width={progress}
        status={status}
        variant={variant}
      >
        {getIcon()}
        <Label>{label}</Label>
      </Container>
    );
  }
);

Button.displayName = 'Button';

Button.defaultProps = {
  fullWidth: false,
  icon: false,
  type: 'button',
  variant: 'action',
};

Button.propTypes = {
  fullWidth: PropTypes.bool,
  icon: PropTypes.oneOfType([PropTypes.element, PropTypes.bool]),
  onClick: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  type: PropTypes.string,
  variant: PropTypes.string,
};

export default Button;
