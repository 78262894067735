import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 10px;
`;

export const IconContainer = styled.label`
  cursor: pointer;

  > input[type='radio'] {
    display: none;

    &:checked + div {
      border-color: #007aff;
      background-color: #007aff;

      > svg {
        color: #ffffff;
      }
    }

    &:not(:checked) + div {
      &:hover {
        border-color: #007aff;
        background-color: #007aff10;

        > svg {
          color: #007aff;
        }
      }
    }
  }
`;

export const Icon = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  border: 1px solid #eee;
  transition: background-color 150ms linear, border-color 150ms linear;

  > svg {
    font-size: 24px;
    transition: color 150ms linear;
    color: #3a3a3c;
  }
`;
