import styled from 'styled-components';
// import fadeIn from '~/styles/animations/fade-in';

export const Container = styled.div`
  height: 100%;
  padding: 30px;

  overflow-y: scroll;
`;

export const Header = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 30px;
`;

export const BoxIcon = styled.div`
  display: flex;
  align-items: center;

  font-size: 20px;
  color: #3a3a3c;
`;
