export const primary = {
  normal: '#007AFF',
  light: '#1dadff',
  dark: '#1269ec',
};

export const secondary = {
  normal: '#ff9500',
  light: '#ffa425',
  dark: '#f57902',
};

const error = {
  normal: '#FF3B30',
  light: '#f94d4c',
  dark: '#f02f30',
};

export default {
  primary,
  secondary,
  action: { ...primary },
  success: {
    normal: '#35C759',
    light: '#65d279',
    dark: '#00bc38',
  },
  warning: {
    normal: '#FDCC00',
    light: '#fdd542',
    dark: '#fdc100',
  },
  error,
  danger: { ...error },
  black: '#010101',
  white: '#ffffff',
};
