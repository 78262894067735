import { useCallback, useEffect, useRef, useState } from 'react';

import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { useField } from '@unform/core';
import pt from 'date-fns/locale/pt';
import PropTypes from 'prop-types';

// Style
import { Container, TimePickerField, Error } from './styles';

const TimePicker = ({ name, label, ...rest }) => {
  const inputRef = useRef({ value: new Date() });

  const { defaultValue, error, fieldName, registerField } = useField(name);

  const [time, setTime] = useState(new Date());

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
      setValue: (ref, value) => {
        const currentDate = new Date();
        if (value) {
          const [hour, min, sec] = value.split(':');

          currentDate.setHours(hour, min, sec);
        }

        ref.value = currentDate;
        setTime(currentDate);
      },
    });
  }, [fieldName, registerField]);

  // Default value
  useEffect(() => {
    if (defaultValue && inputRef.current) {
      const [hour, min, sec] = defaultValue.split(':');

      const currentDate = new Date();
      currentDate.setHours(hour, min, sec);

      inputRef.current.value = currentDate;
      setTime(currentDate);
    }
  }, [defaultValue]);

  // On change
  const handleChange = useCallback(
    (value) => {
      inputRef.current.value = value;

      setTime(value);

      if (rest.onChange) {
        rest.onChange(value);
      }
    },
    [rest]
  );

  return (
    <Container className="material-time-picker">
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={pt}>
        <TimePickerField
          inputRef={inputRef}
          ampm={false}
          invalidDateMessage="Fomato inválido"
          error={!!error}
          fullWidth
          KeyboardButtonProps={{
            'aria-label': 'change time',
          }}
          label={label}
          margin="normal"
          onChange={handleChange}
          value={time}
          variant="inline"
          {...rest}
        />
      </MuiPickersUtilsProvider>
      {error && <Error>{error}</Error>}
    </Container>
  );
};

TimePicker.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

export default TimePicker;
