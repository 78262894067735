import PropTypes from 'prop-types';

import formatDate from '~/utils/formatDate';

import {
  Container,
  Title,
  Content,
  InfoContainer,
  InfoContent,
  PersonsContainer,
  Label,
  Value,
} from './styles';
// import masks from '~/utils/masks';

const Interest = ({ data }) => {
  const {
    checkout,
    checkin,
    hospedagem,
    adultos,
    criancas,
    infantil,
    mensagem,
    acao,
    data: date,
  } = data;

  const getCheckoutString = (checkoutDate) => {
    const string = formatDate(checkoutDate).toString();

    if (string === 'Invalid Date') {
      return '-';
    }

    return string;
  };

  return (
    <Container>
      <Title>
        {acao} - {formatDate(date, { hour: 'numeric', minute: 'numeric' })}
      </Title>
      <Content>
        <InfoContainer>
          <InfoContent>
            <Label>CheckIn</Label>
            <Value>{formatDate(checkin)}</Value>
          </InfoContent>
          <InfoContent>
            <Label>CheckOut</Label>
            <Value>{getCheckoutString(checkout)}</Value>
          </InfoContent>
        </InfoContainer>
      </Content>

      <Content>
        <InfoContent>
          <Label>Hospedagem</Label>
          <Value>{hospedagem || 'Não informada'}</Value>
        </InfoContent>
      </Content>

      <PersonsContainer>
        <InfoContent>
          <Label>Adultos</Label>
          <Value>{adultos}</Value>
        </InfoContent>
        <InfoContent>
          <Label>Crianças</Label>
          <Value>{criancas}</Value>
        </InfoContent>
        <InfoContent>
          <Label>Infantil</Label>
          <Value>{infantil}</Value>
        </InfoContent>
      </PersonsContainer>

      <InfoContent>
        <Label>Mensagem</Label>
        <Value multiline>{mensagem || 'Sem mensagem'}</Value>
      </InfoContent>
    </Container>
  );
};

Interest.propTypes = {
  data: PropTypes.shape({
    hospedagem: PropTypes.string,
    checkin: PropTypes.string,
    checkout: PropTypes.string,
    adultos: PropTypes.string,
    criancas: PropTypes.string,
    infantil: PropTypes.string,
    data: PropTypes.string,
    mensagem: PropTypes.string,
    acao: PropTypes.string,
  }).isRequired,
};

export default Interest;
