import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;

  overflow: hidden;
  height: 100%;
  width: 100%;
  border-radius: 16px;
  box-shadow: 0 4px 8px rgba(154, 160, 185, 0.05),
    0 7px 20px rgba(166, 173, 201, 0.2);
  background-color: #ffffff;
`;

export const Content = styled.div`
  width: 100%;
`;

export const Image = styled.div`
  position: relative;
  height: 200px;

  background-size: cover;
  background-color: #fafafa;
  background-repeat: no-repeat;
  background-image: ${({ src }) => src && `url('${src}'), `}
    url('https://cdn.montacasa.com.br/assets/images/no-image-default.png');
`;

export const Type = styled.div`
  position: absolute;
  bottom: 10px;
  left: 10px;
  font-size: 14px;
  color: #3a3a3c;
  padding: 2px 5px;
  border-radius: 2px;
  background-color: #ffffff;
`;

export const Description = styled.div`
  width: 100%;
  padding: 15px;

  .name {
    font-size: 20px;
    font-weight: 500;
    color: #1c1c1e;
  }
`;

export const Updater = styled.div`
  display: flex;
  align-items: flex-end;

  width: 100%;

  padding: 15px;
`;

export const UpdaterInitials = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-right: 7px;
  height: 35px;
  width: 35px;
  font-size: 16px;
  font-weight: 500;
  color: #8e8e93;
  background-color: #eeeeee;
`;

export const UpdaterPhoto = styled.div`
  border-radius: 50%;
  margin-right: 7px;
  height: 35px;
  width: 35px;
  background-size: cover;
  background-color: #fafafa;
  background-repeat: no-repeat;
  background-image: ${({ src }) => `url('${src}')`};
`;

export const UpdaterInfo = styled.div``;

export const UpdaterInfoName = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: #3a3a3c;
`;
export const UpdaterInfoTime = styled.div`
  font-size: 12px;
  color: #8e8e93;
`;

export const DescriptionHead = styled.div`
  position: relative;
  width: 100%;
`;

export const DescriptionTitle = styled.div`
  text-transform: capitalize;
`;

export const DescriptionButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  height: 30px;
  width: 30px;
  border: none;
  border-radius: 50%;
  background-color: #eee;

  svg {
    font-size: 16px;
    color: #707070;
  }
`;
