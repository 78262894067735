import { useCallback, useEffect, useRef, useState } from 'react';

import { useField } from '@unform/core';
import PropTypes from 'prop-types';

import { Container, Content, Label, Field, Item, ErrorMessage } from './styles';

const Select = ({ label, name, mask, fullWidth, items, onChange, ...rest }) => {
  const selectRef = useRef(null);

  const [selectedValue, setSelectedValue] = useState('');

  // defaultValue
  const { fieldName, registerField, error, defaultValue = '' } = useField(name);

  useEffect(() => {
    if (selectRef.current)
      registerField({
        name: fieldName,
        ref: selectRef.current,
        path: 'node.value',
        setValue: (ref, value) => {
          setSelectedValue(value);
        },
      });
  }, [fieldName, registerField]);

  // To use masks
  const handleChange = useCallback((event) => {
    event.persist();

    const { value } = event.target;

    setSelectedValue(value);

    if (onChange) {
      onChange(value);
    }
  }, []);

  useEffect(() => {
    if (defaultValue && items.length > 0) {
      setSelectedValue(defaultValue);
    }
  }, [defaultValue, items]);

  return (
    <Container className="root-select">
      <Content fullWidth={fullWidth}>
        <Label id={name + label}>{label}</Label>
        <Field
          id={fieldName}
          labelId={name + label}
          inputRef={selectRef}
          name={fieldName}
          onChange={handleChange}
          error={!!error}
          fullWidth={fullWidth}
          value={selectedValue}
          aria-describedby={fieldName}
          {...rest}
        >
          {items.map((item) => (
            <Item key={item.value} value={item.value}>
              {item.label}
            </Item>
          ))}
        </Field>
        <ErrorMessage>{error}</ErrorMessage>
      </Content>
    </Container>
  );
};

Select.defaultProps = {
  label: '',
  mask: '',
  fullWidth: true,
  items: [],
  onChange: () => {},
};

Select.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  fullWidth: PropTypes.bool,
  mask: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ),
  onChange: PropTypes.func,
};

export default Select;
