import { createActions, createReducer } from 'reduxsauce';

export const { Types, Creators } = createActions({
  setLodgingCoverId: ['id'],
});

const INITIAL_STATE = 0;

const setLodgingCoverId = (state, { id }) => id;

export default createReducer(INITIAL_STATE, {
  [Types.SET_LODGING_COVER_ID]: setLodgingCoverId,
});
