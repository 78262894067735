import { useContext } from 'react';

import PropTypes from 'prop-types';
import { FiTrash2 } from 'react-icons/fi';
import { useParams } from 'react-router-dom';

import CircleButton from '~/components/CircleButton';
import ModalDeleteContext from '~/components/ModalDelete/ModalContext';

import { Container } from './styles';

const DeleteButton = ({ onDelete, imageId }) => {
  const { id } = useParams();

  const { openModalDelete } = useContext(ModalDeleteContext);

  const handleClickDeleteImage = () => {
    openModalDelete({
      url: `/lodgings/${id}/images/${imageId}`,
      title: 'Deseja deletar a imagem?',
      onDelete,
      autoClose: true,
      messages: {
        success: 'Sucesso ao deletar imagem!',
        error: 'Erro ao deletar imagem...',
      },
    });
  };

  return (
    <Container>
      <CircleButton
        variant="danger"
        tooltip="Deletar imagem"
        icon={<FiTrash2 />}
        onClick={handleClickDeleteImage}
      />
    </Container>
  );
};

DeleteButton.propTypes = {
  onDelete: PropTypes.func.isRequired,
  imageId: PropTypes.number.isRequired,
};

export default DeleteButton;
