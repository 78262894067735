import styled from 'styled-components';

import { Form } from '@unform/web';

export const Container = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 102;
  top: 0;
  left: 0;
  background-color: #00000040;

  display: flex;
  align-items: center;
  justify-content: center;

  .container-input {
    padding-top: 5px;
    input {
      padding: 10px;
    }
  }
`;

export const Content = styled(Form)`
  width: 100%;
  max-width: 500px;
  background-color: #ffffff;
  border-radius: 12px;
  padding: 60px;
  box-shadow: 1px 6px 12px rgba(0, 0, 0, 0.1);
`;

export const Title = styled.h1`
  margin-bottom: 30px;
  font-size: 24px;

  color: #1c1c1e;
`;

export const Controls = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-top: 30px;

  button {
    padding: 10px;

    &:first-of-type {
      margin-right: 15px;
      border: 1px solid #636366;
      background-color: transparent;

      &:hover {
        border-color: #48484a;
        background-color: transparent;
      }

      span {
        color: #636366;

        &:not(:disabled):hover {
          color: #48484a;
        }
      }
    }
  }
`;

export const Instruction = styled.div`
  font-size: 14px;
  color: #2c2c2e;
`;

export const DeleteString = styled.span`
  color: #1c1c1e;
  font-weight: 700;
`;
