import styled from 'styled-components';

import devices from '~/styles/configs/devices';

export const Container = styled.div`
  display: grid;
  grid-template-columns: 260px 1fr;
  grid-template-rows: 60px calc(100vh - 60px);
  grid-template-areas:
    'top_menu top_menu'
    'side_menu content';

  @media ${devices.laptopL} {
    grid-template-columns: 300px 1fr;
  }
`;

export const Content = styled.div`
  overflow-y: hidden;
  grid-area: content;
  background-color: #ffffff;
  box-shadow: 0 4px 8px rgba(154, 160, 185, 0.05),
    0 7px 20px rgba(166, 173, 201, 0.2);
`;
