import styled from 'styled-components';

export const Container = styled.div`
  cursor: pointer;
  user-select: none;

  display: flex;
  align-items: center;
  justify-content: center;

  height: 26px;
  width: 26px;

  border-radius: 4px;
  background-color: #0073f0;

  svg {
    pointer-events: none;

    color: #ffffff;
  }
`;
