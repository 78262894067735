import PropTypes from 'prop-types';
import { FaBookmark, FaRegBookmark } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import CircleButton from '~/components/CircleButton';
import api from '~/services/api';
import { Creators as LodgingIdCoverActions } from '~/store/ducks/lodgingCoverId';

const CoverButton = ({ imageId }) => {
  const { id } = useParams();

  const dispatch = useDispatch();
  const coverId = useSelector((state) => state.lodgingCoverId);

  const handleClickSetCover = async () => {
    try {
      const res = await api.post(`/lodgings/${id}/cover`, {
        lodging_image_id: imageId,
      });

      if (res.status === 200) {
        dispatch(LodgingIdCoverActions.setLodgingCoverId(imageId));
        toast.success('Sucesso ao selecionar a imagem de capa!');
      }
    } catch (error) {
      if (error.response.data.error) {
        toast.error(error.response.data.error);
      } else {
        toast.error('Erro ao selecionar a imagem de capa...');
      }
    }
  };

  const getIcon = () => {
    if (imageId === coverId) return <FaBookmark />;

    return <FaRegBookmark />;
  };

  return (
    <CircleButton
      tooltip={
        coverId !== imageId ? 'Definir como capa' : 'Essa imagem já é a capa'
      }
      icon={getIcon()}
      disabled={coverId === imageId}
      onClick={handleClickSetCover}
      colorIcon={coverId === imageId ? '#35C759' : '#8E8E93'}
    />
  );
};

CoverButton.propTypes = {
  imageId: PropTypes.number.isRequired,
};

export default CoverButton;
