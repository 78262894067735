import { createActions, createReducer } from 'reduxsauce';

export const { Types, Creators } = createActions({
  setStatusRequest: ['inProgress'],
});

const INITIAL_STATE = { inProgress: false };

const setStatusRequest = (state, { inProgress }) => {
  return { inProgress };
};

export default createReducer(INITIAL_STATE, {
  [Types.SET_STATUS_REQUEST]: setStatusRequest,
});
