import { useCallback, useEffect, useRef, useState } from 'react';

import formatDistance from 'date-fns/formatDistance';
import pt from 'date-fns/locale/pt';
import { toast } from 'react-toastify';

import Actions from '~/components/Actions';
import Button from '~/components/Button';
import Header from '~/components/Header';
import Pagination from '~/components/Pagination';
import Table from '~/components/Table';
import TableActions from '~/components/TableActions';
import PrivateLayout from '~/layouts/Private';
import api from '~/services/api';

import Form from './Form';
import { Container, Capitalize } from './styles';

const INITIAL_USERS = {
  data: [],
  page: 0,
  perPage: 0,
  total: 0,
  lastPage: 0,
};

const User = () => {
  const formRef = useRef();

  const [users, setUsers] = useState(INITIAL_USERS);

  const getUsers = useCallback(async (page = 1) => {
    try {
      const response = await api.get(
        `users?page=${page}&perPage=10&active=true&orderBy=updated_at DESC`
      );

      setUsers(response.data);
    } catch (error) {
      toast.error('Erro ao buscar pessoas...');
      setUsers(INITIAL_USERS);
    }
  }, []);

  useEffect(() => {
    getUsers();
  }, [getUsers]);

  const handleClickOpenForm = () =>
    formRef.current.setData({
      type: 'C',
    });

  const handleClickEdit = (data) => formRef.current.setData(data);

  const handleOnCloseForm = (reload) => {
    if (reload) getUsers();
  };

  const columns = [
    {
      key: 'name',
      label: 'Nome',
      format: (name) => <Capitalize>{name}</Capitalize>,
    },
    {
      key: 'updated_at',
      label: 'Atualizado',
      format: (value) =>
        formatDistance(new Date(value), new Date(), {
          locale: pt,
          addSuffix: true,
        }),
    },
    {
      label: '',
      width: 50,
      format: (detail) => {
        return <TableActions onEdit={() => handleClickEdit(detail)} />;
      },
    },
  ];

  return (
    <PrivateLayout>
      <Container>
        <Header title="Usuários" total={users.total} />
        <Actions align="flex-end">
          <Button label="Novo usuário" onClick={handleClickOpenForm} />
        </Actions>
        <Form ref={formRef} onClose={handleOnCloseForm} />
        <Table columns={columns} rows={users.data} />
        <Pagination
          onChange={getUsers}
          page={users.page}
          perPage={users.perPage}
          total={users.total}
          lastPage={users.lastPage}
          position="flex-end"
        />
      </Container>
    </PrivateLayout>
  );
};

export default User;
