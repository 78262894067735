import styled from 'styled-components';

export const Container = styled.div`
  position: sticky;

  bottom: 0;
  left: 0;

  width: 100%;
  padding: 0 30px;

  background-color: #ffffff;
`;

export const Content = styled.div`
  display: flex;
  justify-content: flex-end;

  padding: 20px 0;

  border-top: 1px solid #eeeeee;
`;
