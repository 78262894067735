import styled from 'styled-components';

export const Container = styled.div`
  height: 100%;
  width: 100%;

  overflow-y: scroll;

  padding: 30px;
`;
