import styled, { css, keyframes } from 'styled-components';

const rotating = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const Container = styled.button`
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  overflow: hidden;

  padding: 12px 16px;
  border-radius: 4px;

  border: none;
  transition: background-color 200ms linear;

  ${({ fullWidth }) =>
    fullWidth &&
    css`
      width: 100%;
    `}

  ${({ variant }) => {
    return css`
      background-color: ${({ theme }) => theme[variant].normal};

      &:not(:disabled):hover {
        background-color: ${({ theme }) => theme[variant].dark};
      }
    `;
  }}

  ${({ width }) => {
    if (width > 0 && width < 100) {
      return css`
        &:disabled svg {
          animation: ${rotating} 2s linear infinite;
        }
      `;
    }

    return css``;
  }}

  &:after {
    content: '';

    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;

    height: 100%;
    width: ${({ width }) => width}%;

    ${({ width, status, theme, variant }) => {
      if (width === 100 && status === 'success') {
        return css`
          background-color: ${theme.success.normal};
        `;
      }

      if (width === 100 && status === 'error') {
        return css`
          background-color: ${theme.error.normal};
        `;
      }

      return css`
        background-color: ${theme[variant].dark};
      `;
    }}

    transition: width 200ms linear, background-color 200ms linear;
  }
`;

export const IconBox = styled.span`
  position: sticky;
  z-index: 2;

  display: flex;
  align-items: center;

  margin-right: 8px;

  svg {
    pointer-events: none;
    color: #ffffff;
  }
`;

export const Label = styled.span`
  position: sticky;
  z-index: 2;

  color: #ffffff;

  font-size: 14px;
  font-weight: 500;
`;
