import styled from 'styled-components';

export const Container = styled.div`
  position: absolute;

  bottom: 7px;
  left: 7px;

  font-size: 14px;
  color: #aeaeb2;
`;
