import styled, { css } from 'styled-components';

// import fadeIn from '~/styles/animations/fade-in';
import { etapaStyles, statusStyles } from '~/styles/configs/attendance';

export const Container = styled.div`
  height: 100%;
  padding: 30px;

  overflow-y: scroll;
`;

export const Capitalize = styled.span`
  text-transform: capitalize;
`;

export const ColumnLine = styled.div`
  &:not(:last-of-type) {
    margin-bottom: 5px;
  }
`;

export const AttendanceStatus = styled.div`
  display: flex;
  align-items: center;

  .etapa {
    border-radius: 10px;
    padding: 4px 8px;

    ${({ etapa }) => {
      if (etapaStyles.colors[etapa]) {
        return css`
          color: ${etapaStyles.colors[etapa]};
          background-color: ${etapaStyles.backgrounds[etapa]};
          border: ${etapaStyles.borders[etapa]};
        `;
      }

      return css`
        color: ${etapaStyles.colors.default};
        background-color: ${etapaStyles.backgrounds.default};
        border: ${etapaStyles.borders.default};
      `;
    }}
  }

  .status {
    margin-left: 5px;
    border-radius: 10px;
    padding: 4px 8px;

    ${({ status }) => {
      if (statusStyles.colors[status]) {
        return css`
          color: ${statusStyles.colors[status]};
          background-color: ${statusStyles.backgrounds[status]};
        `;
      }

      return css`
        color: ${statusStyles.colors.default};
        background-color: ${statusStyles.backgrounds.default};
      `;
    }}
  }
`;

export const TableButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const AttendanceButton = styled.span`
  cursor: pointer;
  user-select: none;

  display: flex;
  align-items: center;
  justify-content: center;

  height: 26px;
  width: 26px;
  margin-right: 5px;

  border-radius: 4px;
  background-color: #0073f0;

  svg {
    pointer-events: none;

    color: #ffffff;
  }
`;
