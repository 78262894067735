import styled, { css } from 'styled-components';

import { Link } from 'react-router-dom';

export const Container = styled(Link)`
  cursor: pointer;
  user-select: none;

  display: flex;
  align-items: center;
  border-radius: 4px;

  height: 100%;

  font-size: 14px;
  font-weight: 500;
  color: #636366;

  &:not(:last-of-type) {
    margin-bottom: 5px;
  }

  ${({ active, disabled }) =>
    active &&
    !disabled &&
    css`
      color: #007aff;

      background-color: #eeeeee;
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
      cursor: not-allowed;
      color: #aeaeb2;
    `}
`;

export const Item = styled.span`
  display: flex;
  align-items: center;

  width: 100%;
  padding: 12px 8px;
  border-radius: 4px;
  transition: background-color 200ms linear, color 200ms linear;

  :hover {
    color: #007aff;
    background-color: #eeeeee;
  }

  svg {
    margin-right: 8px;
    font-size: 18px;
  }
`;
