import styled, { css } from 'styled-components';

import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

export const Container = styled.div``;

export const Content = styled(FormControl)`
  width: 100%;

  ${({ fullWidth }) => {
    if (fullWidth)
      return css`
        max-width: none;
      `;

    return css`
      max-width: 300px;
    `;
  }}

  label.Mui-focused:not(.Mui-error) {
    color: #007aff;
  }

  .MuiInput-underline:after {
    border-bottom-color: #007aff;
  }
`;

export const Label = styled(InputLabel)``;

export const Field = styled(Select)``;

export const Item = styled(MenuItem)``;

export const ErrorMessage = styled(FormHelperText)``;
