import styled from 'styled-components';

export const Container = styled.div`
  grid-area: top_menu;

  z-index: 1;

  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  height: 100%;
  padding: 0 32px;

  background-color: #ffffff;
  box-shadow: 0 4px 8px rgba(154, 160, 185, 0.05),
    0 7px 20px rgba(166, 173, 201, 0.2);
`;

export const LeftContent = styled.div`
  display: flex;

  position: relative;
`;

export const RightContent = styled.div`
  align-items: center;
`;

export const Profile = styled.div`
  display: flex;
  align-items: center;
`;
