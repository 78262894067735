import { useCallback, useEffect, useRef, useState } from 'react';

import { parseISO } from 'date-fns';
import { IoIosAdd, IoIosFunnel } from 'react-icons/io';
import { MdPersonOutline } from 'react-icons/md';
// import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import Actions from '~/components/Actions';
import Button from '~/components/Button';
import Header from '~/components/Header';
import Pagination from '~/components/Pagination';
import Table from '~/components/Table';
import TableActions from '~/components/TableActions';
import useWebService from '~/hooks/useWebService';
import PrivateLayout from '~/layouts/Private';
import formatDate from '~/utils/formatDate';

import TransferForm from '../LeadDetail/TransferForm';
import LeadForm from './LeadForm';
import SearchForm from './SearchForm';
import {
  Container,
  Capitalize,
  ColumnLine,
  AttendanceStatus,
  TableButtons,
  AttendanceButton,
} from './styles';

const INITIAL_LEADS = {
  dados: [],
  page: 0,
  perPage: 0,
  total: 0,
  lastPage: 0,
};

const Lead = () => {
  const leadFormRef = useRef();
  const searchFormRef = useRef();
  const transferFormRef = useRef();
  const ws = useWebService();
  const history = useHistory();
  const [leads, setLeads] = useState(INITIAL_LEADS);
  const [firstLoad, setFirstLoad] = useState(true);
  // const user = useSelector((state) => state.user);

  const getLeads = useCallback(async () => {
    try {
      const queryParams = new URLSearchParams(history.location.search);

      let status = '';
      if (queryParams.has('status')) {
        status = queryParams.get('status');
      } else if (firstLoad) {
        status = 'Aberto';
      }

      // let atendente = '';
      // if (queryParams.has('atendente_id')) {
      //   atendente = queryParams.get('atendente_id');
      // } else if (firstLoad) {
      //   atendente = user.id;
      // }

      const response = await ws.get(`atendimento/retornaClientes.php`, {
        params: {
          page: queryParams.get('page') || 1,
          perPage: queryParams.get('perPage') || 10,
          order: queryParams.get('order') || 'DESC',
          status,
          etapa: queryParams.get('etapa') || '',
          nome: queryParams.get('nome') || '',
          email: queryParams.get('email') || '',
          telefone: queryParams.get('telefone') || '',
          data_in: queryParams.get('data_in') || '',
          data_out: queryParams.get('data_out') || '',
          atendente_id: queryParams.get('atendente_id') || '',
          disponibilidade_negocio:
            queryParams.get('disponibilidade_negocio') || '',
          proposito: queryParams.get('proposito') || '',
        },
      });

      if (response.data.result) {
        setLeads(response.data);
      } else {
        toast.error('Erro ao buscar leads...');
        setLeads(INITIAL_LEADS);
      }
    } catch (error) {
      toast.error('Erro ao buscar leads...');
      setLeads(INITIAL_LEADS);
    }
  }, [ws, history.location.search]);

  useEffect(() => {
    getLeads();
  }, [history.location.search]);

  const goToPage = useCallback((page, perPage) => {
    const queryParams = new URLSearchParams(history.location.search);
    queryParams.set('page', page);
    queryParams.set('perPage', perPage);
    history.push({
      pathname: '/atendimento/leads',
      search: queryParams.toString(),
    });
  }, []);

  const handleClickView = (leadId, businessId) => {
    history.push({
      pathname: `/atendimento/leads/${leadId}/detalhes/${businessId}`,
    });
  };

  const handleOpenTransferForm = (lead) => {
    transferFormRef.current.setData({
      atendente_novo_id: lead.atend_atendente_id,
      atendente_atual: lead.atend_atendente_nome,
      atendimento_id: lead.atend_id,
    });
  };

  const handleCloseTransferForm = (reload) => {
    if (reload) {
      getLeads();
    }
  };

  const handleOpenLeadForm = () => {
    leadFormRef.current.setData({
      proposito: '',
      is_search: true,
      search: '',
      nome: '',
      email: '',
      telefone: '',
    });
  };

  const handleOpenSearchForm = () => {
    const queryParams = new URLSearchParams(history.location.search);

    let status = '';
    if (queryParams.has('status')) {
      status = queryParams.get('status');
    } else if (firstLoad) {
      status = 'Aberto';
    }

    // let atendente = '';
    // if (queryParams.has('atendente_id')) {
    //   atendente = queryParams.get('atendente_id');
    // } else if (firstLoad) {
    //   atendente = user.id;
    // }

    searchFormRef.current.setData({
      nome: queryParams.get('nome') || '',
      email: queryParams.get('email') || '',
      telefone: queryParams.get('telefone') || '',
      status,
      etapa: queryParams.get('etapa') || '',
      data_in: queryParams.get('data_in')
        ? parseISO(queryParams.get('data_in'))
        : null,
      data_out: queryParams.get('data_out')
        ? parseISO(queryParams.get('data_out'))
        : null,
      atendente_id: queryParams.get('atendente_id') || '',
      disponibilidade_negocio: queryParams.get('disponibilidade_negocio') || '',
      proposito: queryParams.get('proposito') || '',
    });
  };

  const handleCloseLeadForm = (leadData) => {
    if (leadData) {
      handleClickView(leadData.cliente_id, leadData.atendimento_id);
    }
  };

  const handleCloseSearchForm = (searchData) => {
    if (searchData) {
      setFirstLoad(false);
      const queryParams = new URLSearchParams();
      queryParams.set('page', 1);
      queryParams.set('perPage', 10);

      Object.keys(searchData).forEach((key) => {
        if (searchData[key] || key === 'status') {
          queryParams.set(key, searchData[key]);
        }
      });

      history.push({
        pathname: '/atendimento/leads',
        search: queryParams.toString(),
      });
    }
  };

  const columns = [
    {
      key: ['cli_nome', 'atend_proposito'],
      label: 'Cliente',
      format: (data) => (
        <>
          <ColumnLine>
            <Capitalize>{data.cli_nome}</Capitalize>
          </ColumnLine>
          <ColumnLine>
            <Capitalize>{data.atend_proposito}</Capitalize>
          </ColumnLine>
        </>
      ),
    },
    {
      key: ['atend_etapa', 'atend_status'],
      label: 'Etapa & Status',
      format: (data) => (
        <>
          <ColumnLine>
            <AttendanceStatus
              etapa={data.atend_etapa}
              status={data.atend_status}
            >
              <span className="etapa">{data.atend_etapa}</span>
              <span className="status">{data.atend_status}</span>
            </AttendanceStatus>
          </ColumnLine>
        </>
      ),
    },
    {
      key: ['atend_id', 'atend_atendente_nome', 'atend_data_criacao'],
      label: 'Negócio',
      format: (data) =>
        data.atend_id && (
          <>
            {data.atend_data_criacao && (
              <ColumnLine>
                {formatDate(data.atend_data_criacao, {
                  hour: 'numeric',
                  minute: 'numeric',
                })}
              </ColumnLine>
            )}
            {data.atend_atendente_nome && (
              <ColumnLine>{data.atend_atendente_nome}</ColumnLine>
            )}
          </>
        ),
    },
    {
      label: '',
      width: 50,
      format: (lead) => {
        return (
          <TableButtons>
            <AttendanceButton onClick={() => handleOpenTransferForm(lead)}>
              <MdPersonOutline />
            </AttendanceButton>
            <TableActions
              onEdit={() => handleClickView(lead.cli_id, lead.atend_id)}
            />
          </TableButtons>
        );
      },
    },
  ];

  return (
    <PrivateLayout>
      <Container>
        <Header title="Leads" total={leads.total} />
        <Actions align="space-between">
          <Button
            icon={<IoIosAdd />}
            label="Novo"
            onClick={handleOpenLeadForm}
          />
          <Button
            icon={<IoIosFunnel />}
            label="Filtrar"
            onClick={handleOpenSearchForm}
          />
        </Actions>

        <LeadForm ref={leadFormRef} onClose={handleCloseLeadForm} />
        <SearchForm ref={searchFormRef} onClose={handleCloseSearchForm} />

        <Table columns={columns} rows={leads.dados} />
        <Pagination
          onChange={goToPage}
          page={leads.page}
          perPage={leads.perPage}
          total={leads.total}
          lastPage={leads.lastPage}
          position="flex-end"
        />

        <TransferForm ref={transferFormRef} onClose={handleCloseTransferForm} />
      </Container>
    </PrivateLayout>
  );
};

export default Lead;
