import { ThemeProvider } from 'styled-components';

import { createBrowserHistory } from 'history';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';

import ModalDelete from '~/components/ModalDelete';
import Toast from '~/components/Toast';
import Version from '~/components/Version';
import Routes from '~/routes/index';
import { store, persistor } from '~/store/index';
import GlobalStyle from '~/styles/global';
import defaultTheme from '~/styles/theme/default';

const history = createBrowserHistory();
const App = () => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Router history={history}>
          <ThemeProvider theme={defaultTheme}>
            <Toast />
            <GlobalStyle />
            <ModalDelete>
              <Routes />
            </ModalDelete>
            <Version />
          </ThemeProvider>
        </Router>
      </PersistGate>
    </Provider>
  );
};

export default App;
