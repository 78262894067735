import styled, { css } from 'styled-components';

import FormHelperText from '@material-ui/core/FormHelperText';
import TextField from '@material-ui/core/TextField';

export const Container = styled.div`
  width: 100%;

  ${({ fullWidth }) => {
    if (fullWidth)
      return css`
        max-width: none;
      `;

    return css`
      max-width: 300px;
    `;
  }}
`;

export const Field = styled(TextField)`
  label.Mui-focused:not(.Mui-error) {
    color: #007aff;
  }

  .MuiInput-underline:after {
    border-bottom-color: #007aff;
  }
`;

export const ErrorMessage = styled(FormHelperText)``;
