import { forwardRef, useImperativeHandle, useState, useRef } from 'react';

import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import Select from '~/components/Material/Select';
import SideForm from '~/components/SideForm';
import FixedControl from '~/components/SideForm/FixedControl';
import Section from '~/components/SideForm/Section';
import Title from '~/components/SideForm/Title';
import useWebService from '~/hooks/useWebService';
import api from '~/services/api';

import { Content } from './styles';

const INITIAL_USERS = [];

const TransferForm = ({ ...rest }, ref) => {
  const formRef = useRef();
  const buttonRef = useRef();
  const [data, setData] = useState({});
  const [atendimentoId, setAtendimentoId] = useState(null);
  const [atendenteAtual, setAtendenteAtual] = useState('');
  const [users, setUsers] = useState(INITIAL_USERS);
  const user = useSelector((state) => state.user);
  const ws = useWebService();

  useImperativeHandle(
    ref,
    () => ({
      setData: async (value) => {
        setData(value);
        setAtendimentoId(value.atendimento_id);
        setAtendenteAtual(value.atendente_atual);
        try {
          const response = await api.get('/users', {
            params: {
              active: true,
            },
          });

          setUsers(
            response.data.data.map((resUser) => {
              return { label: resUser.name, value: resUser.id };
            })
          );
        } catch (error) {
          toast.error('Não foi possível buscar a lista de usuário...');
        }
      },
    }),
    []
  );

  const closeForm = (shouldReload) => {
    formRef.current.reset();
    formRef.current.setErrors({});
    setData({});
    rest.onClose(shouldReload);
  };

  const handleSubmit = async (formData) => {
    try {
      buttonRef.current.start();

      const newAttendant = users.find(
        (attendant) => attendant.value === Number(formData.atendente_novo_id)
      );

      const response = await ws.post('atendimento/transferirAtendimento.php', {
        ...formData,
        // atendente_novo_id: user.id,
        atendente_novo_nome: newAttendant.label,
        atendente_atual_nome: atendenteAtual,
        usuario_nome: user.name,
        atendimento_id: atendimentoId,
      });

      if (response.data.result) {
        buttonRef.current.finish({
          type: 'success',
          callback: () => closeForm(true),
        });
      } else {
        toast.error(`Erro ao alterar atendente do negócio...`);
        buttonRef.current.finish({
          type: 'error',
          autoReset: true,
          timeReset: 2,
        });
      }

      return 'response';
    } catch (error) {
      buttonRef.current.finish({
        type: 'error',
        autoReset: true,
        timeReset: 2,
      });
      return error;
    }
  };

  const handleOnClose = () => closeForm(false);

  return (
    <SideForm
      formRef={formRef}
      data={data}
      onClose={handleOnClose}
      onSubmit={handleSubmit}
    >
      <Content>
        <Title label="Transferir Negócio para outro Atendente" />
        <Section>
          <Select label="Atendente" name="atendente_novo_id" items={users} />
        </Section>

        <FixedControl buttonRef={buttonRef} buttonLabel="Salvar" />
      </Content>
    </SideForm>
  );
};

export default forwardRef(TransferForm);
