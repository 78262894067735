import styled, { css } from 'styled-components';

export const Container = styled.div`
  padding-right: 15px;
  margin-right: 15px;

  border-right: 1px solid #eeeeee;

  display: flex;
  align-items: center;
`;

export const Avatar = styled.div`
  height: 30px;
  width: 30px;

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 50%;

  background-color: #f5f5f5;

  margin-right: 10px;

  ${({ hasImage, avatar }) => {
    if (hasImage) {
      return css`
        background-image: url('${avatar}')
        background-size: cover;
        background-position: center;
      `;
    }

    return css``;
  }}

  svg {
    color: #c7c7cc;
  }
`;

export const Name = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: #636366;
`;
