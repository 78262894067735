import { useEffect, useRef } from 'react';

import formatDistance from 'date-fns/formatDistance';
import pt from 'date-fns/locale/pt';
import PropTypes from 'prop-types';
import { FaCog } from 'react-icons/fa';

import DropMenuLodgings from '../DropMenuLodgings';
import {
  Container,
  Content,
  Image,
  Type,
  Description,
  Updater,
  UpdaterPhoto,
  UpdaterInitials,
  UpdaterInfo,
  UpdaterInfoName,
  UpdaterInfoTime,
  DescriptionHead,
  DescriptionTitle,
  DescriptionButton,
} from './styles';

const CardLodging = ({ data, onEdit }) => {
  const dropMenuLodgingsRef = useRef(null);

  useEffect(() => {
    if (dropMenuLodgingsRef.current) {
      dropMenuLodgingsRef.current.setData(data);
    }
  }, [data]);

  const handleClickOpenModal = (event) =>
    dropMenuLodgingsRef.current.setAnchor(event);

  const handleClickEdit = () => onEdit(data);

  const { type, name, cover, updater, updated_at } = data;

  return (
    <>
      <DropMenuLodgings ref={dropMenuLodgingsRef} onEdit={handleClickEdit} />
      <Container>
        <Content>
          <Image src={cover ? cover.path_url : 'no-image'}>
            <Type>{type.description}</Type>
          </Image>
          <Description>
            <DescriptionHead>
              <DescriptionTitle className="name">{name}</DescriptionTitle>
              <DescriptionButton
                type="button"
                onClick={(e) => handleClickOpenModal(e)}
              >
                <FaCog />
              </DescriptionButton>
            </DescriptionHead>
          </Description>
        </Content>
        <Updater>
          {updater.avatar_url ? (
            <UpdaterPhoto src={updater.avatar_url} />
          ) : (
            <UpdaterInitials>{updater.name.charAt(0)}</UpdaterInitials>
          )}
          <UpdaterInfo>
            <UpdaterInfoName className="name">{updater.name}</UpdaterInfoName>
            <UpdaterInfoTime className="time">
              {formatDistance(new Date(updated_at), new Date(), {
                locale: pt,
                addSuffix: true,
              })}
            </UpdaterInfoTime>
          </UpdaterInfo>
        </Updater>
      </Container>
    </>
  );
};

CardLodging.propTypes = {
  data: PropTypes.shape({
    cover: PropTypes.shape({
      path_url: PropTypes.string,
    }),
    type: PropTypes.shape({
      description: PropTypes.string.isRequired,
    }).isRequired,
    name: PropTypes.string.isRequired,
    updater: PropTypes.shape({
      avatar_url: PropTypes.string,
      name: PropTypes.string.isRequired,
    }).isRequired,
    updated_at: PropTypes.string.isRequired,
  }).isRequired,
  onEdit: PropTypes.func.isRequired,
};

export default CardLodging;
