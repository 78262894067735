import { useSelector } from 'react-redux';

import PrivateLayout from '~/layouts/Private';

import { Container, Greeting, Name } from './styles';

const Home = () => {
  const { name } = useSelector((state) => state.user);

  const getPeriod = () => {
    const date = new Date();

    const hour = date.getHours();

    switch (true) {
      case hour >= 6 && hour < 12:
        return 'Bom dia';
      case hour >= 12 && hour < 18:
        return 'Boa tarde';

      default:
        return 'Boa noite';
    }
  };

  const emoji = '👋🏻';

  return (
    <PrivateLayout>
      <Container>
        <Greeting>
          {emoji} {getPeriod()}
        </Greeting>
        <Name>Sr. {name}!</Name>
      </Container>
    </PrivateLayout>
  );
};

export default Home;
