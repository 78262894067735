import styled from 'styled-components';

import MUIMenuItem from '@material-ui/core/MenuItem';
import { Link as RouterLink } from 'react-router-dom';

export const MenuItem = styled(MUIMenuItem)`
  padding: 0;
`;

export const Button = styled.button`
  background: none;
  border: none;

  width: 100%;
  color: #1c1c1e;
  font-size: 14px;
  padding: 6px 16px;
`;

export const Link = styled(RouterLink)`
  text-decoration: none;
  width: 100%;
  color: #1c1c1e;
  font-size: 14px;
  padding: 6px 16px;
`;
