import styled from 'styled-components';

export const Container = styled.div`
  font-size: 24px;
  font-weight: 700;

  color: #1c1c1e;

  padding: 60px 60px 30px 60px;
`;
