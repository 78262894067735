import yup from '~/libs/yup';

export const createSchema = yup.object({
  description: yup.string().required().label('Nome'),
  quantity: yup.number().required().label('Taxa CHD'),
});

export const updateSchema = yup.object({
  description: yup.string().required().label('Nome'),
  quantity: yup.number().required().label('Taxa CHD'),
});
