import PropTypes from 'prop-types';
import { IoIosArrowRoundBack } from 'react-icons/io';
import { useHistory } from 'react-router-dom';

import { Container, BackButton, Title, Total } from './styles';

const Header = ({ backButton, title, total }) => {
  const history = useHistory();

  const goBack = () => {
    history.goBack();
  };

  return (
    <Container>
      {backButton && (
        <BackButton type="button" onClick={goBack}>
          <IoIosArrowRoundBack size="30" />
        </BackButton>
      )}
      <div>
        <Title>{title}</Title>
        <Total>{total} registros</Total>
      </div>
    </Container>
  );
};

Header.defaultProps = {
  backButton: false,
};

Header.propTypes = {
  backButton: PropTypes.bool,
  title: PropTypes.string.isRequired,
  total: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

export default Header;
