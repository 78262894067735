import {
  forwardRef,
  useContext,
  useImperativeHandle,
  useState,
  useRef,
} from 'react';

import format from 'date-fns/format';
import localePt from 'date-fns/locale/pt';

import Input from '~/components/Material/Input';
import TimePicker from '~/components/Material/TimePicker';
import ModalDeleteContext from '~/components/ModalDelete/ModalContext';
import RadioGroup from '~/components/RadioGroup';
import SideForm from '~/components/SideForm';
import DangerZone from '~/components/SideForm/DangerZone';
import FixedControl from '~/components/SideForm/FixedControl';
import Section from '~/components/SideForm/Section';
import SubTitle from '~/components/SideForm/SubTitle';
import Title from '~/components/SideForm/Title';
import api from '~/services/api';
import yupValidate from '~/utils/yupValidate';
import { storeSchema } from '~/validators/lodging.schemas';

import SelectPerson from './SelectPerson';
import SelectTypeLodging from './SelectTypeLodging';
import { Content, HelpText } from './styles';

const Form = ({ ...rest }, ref) => {
  const { openModalDelete } = useContext(ModalDeleteContext);

  const formRef = useRef();
  const buttonRef = useRef();

  const [lodging, setLodging] = useState({});

  useImperativeHandle(
    ref,
    () => ({
      setData: (value) => setLodging(value),
    }),
    []
  );

  const closeForm = (reload) => {
    formRef.current.setErrors({});
    formRef.current.reset();
    setLodging({});
    rest.onClose(reload);
  };

  const storeLodging = async (data) => {
    try {
      buttonRef.current.start();
      // Dados básicos
      const response = await api.post('/lodgings', data);

      if (response.status === 201) {
        buttonRef.current.finish({
          type: 'success',
          callback: () => closeForm(true),
        });
      }
      return 'response';
    } catch (error) {
      // if (error.response) {
      //   const { status } = error.response;

      //   if (status === 400) {
      //     return error;
      //   }
      // }

      buttonRef.current.finish({
        type: 'error',
        autoReset: true,
        timeReset: 2,
      });
      return error;
    }
  };

  const updateLodging = async (data) => {
    try {
      buttonRef.current.start();

      // Dados básicos
      const response = await api.put(`/lodgings/${lodging.id}`, data);

      if (response.status === 200) {
        buttonRef.current.finish({
          type: 'success',
          callback: () => closeForm(true),
        });
      }
      return 'response';
    } catch (error) {
      // if (error.response) {
      //   const { status } = error.response;

      //   if (status === 400) {
      //     return error;
      //   }
      // }

      buttonRef.current.finish({
        type: 'error',
        autoReset: true,
        timeReset: 2,
      });
      return error;
    }
  };

  const handleSubmit = async (formData) => {
    // Limpa os erros
    formRef.current.setErrors({});

    // Valida os campos
    const { success, data, errors } = await yupValidate(storeSchema, {
      ...formData,
    });

    if (!success) {
      return formRef.current.setErrors(errors);
    }

    const { checkin_time, checkout_time } = data;
    data.checkin_time = format(new Date(checkin_time), 'HH:mm', {
      locale: localePt,
    });
    data.checkout_time = format(new Date(checkout_time), 'HH:mm', {
      locale: localePt,
    });

    if (lodging.id) {
      return updateLodging(data);
    }

    return storeLodging(data);
  };

  const handleOnClose = () => closeForm(false);

  const handleDelete = () => {
    openModalDelete({
      url: `/lodgings/${lodging.id}`,
      title: 'Deseja deletar a hospedagem?',
      onDelete: () => closeForm(true),
      autoClose: true,
      messages: {
        success: 'Sucesso ao deletar a hospedagem!',
        error: 'Erro ao deletar a hospedagem...',
      },
    });
  };

  return (
    <SideForm
      formRef={formRef}
      data={lodging}
      onClose={handleOnClose}
      onSubmit={handleSubmit}
    >
      <Content>
        <Title
          label={
            lodging.id
              ? `Editando hospedagem - ${lodging.id}`
              : 'Nova hospedagem'
          }
        />
        <Section>
          <SubTitle label="Dados básicos" />
          <Input label="Nome" name="name" />
          <SelectPerson />
          <SelectTypeLodging />
          <Input label="Comissão %" name="commission" type="number" />
        </Section>
        <Section>
          <SubTitle label="Tarifa infantil" />
          <HelpText>
            Selecionar como será cobrada a tarifa infantil após exceder o limite
            de cortesias.
          </HelpText>
          <RadioGroup
            name="courtesy_type"
            items={[
              {
                label: 'Incrementar adultos',
                value: 0,
              },
              {
                label: 'Cobrar taxa CHD',
                value: 1,
              },
            ]}
          />
        </Section>
        <Section>
          <SubTitle label="Horários" />
          <TimePicker label="CheckIn" name="checkin_time" />
          <TimePicker label="CheckOut" name="checkout_time" />
        </Section>
        {lodging.id && (
          <DangerZone message="Deletar hospedagem" onClick={handleDelete} />
        )}
        <FixedControl buttonRef={buttonRef} />
      </Content>
    </SideForm>
  );
};

export default forwardRef(Form);
