import styled from 'styled-components';

export const Container = styled.div`
  padding: 15px 0;

  &:not(:last-of-type) {
    border-bottom: 1px solid #eee;
  }
`;

export const Title = styled.div`
  font-size: 16px;
  font-weight: 500;
  color: #1c1c1e;

  margin-bottom: 20px;
`;

export const Value = styled.div`
  font-size: 14px;
  color: #3a3a3c;
  white-space: pre-wrap;
`;
