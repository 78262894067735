import { useState, useEffect, forwardRef } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';

import api from '~/services/api';
import { Creators as LodgingIdCoverActions } from '~/store/ducks/lodgingCoverId';

import { Container, Title, Total } from './styles';

const Header = () => {
  // Lodging
  const { id } = useParams();
  const { state } = useLocation();

  const dispatch = useDispatch();

  const total = useSelector(({ lodgingTotalImages }) => lodgingTotalImages);

  const [title, setTitle] = useState('');

  useEffect(() => {
    const getLodging = async () => {
      const res = await api.get(`/lodgings/${id}`);

      if (res.status === 200) {
        const { person, cover_id } = res.data;
        setTitle(person.name);

        dispatch(LodgingIdCoverActions.setLodgingCoverId(cover_id));
      }
    };

    if (!state) {
      getLodging();
    } else {
      setTitle(state.person.name);
    }
  }, [dispatch, api, state, id]);

  return (
    <Container>
      <Title>{title} - Imagens</Title>
      <Total>{total} registros</Total>
    </Container>
  );
};

export default forwardRef(Header);
