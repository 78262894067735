import styled from 'styled-components';

export const Container = styled.div`
  margin-bottom: 15px;
  display: flex;
`;

export const BackButton = styled.button`
  cursor: pointer;
  position: relative;
  margin-right: 15px;

  display: flex;
  align-items: center;

  border: none;
  background-color: transparent;

  &:hover {
    :before {
      content: 'voltar';
      position: absolute;
      bottom: 0;
      right: 50%;
      padding: 5px 7px;
      border-radius: 3px;
      color: #ffffff;
      background-color: rgba(0, 0, 0, 0.8);
      transform: translate(50%, 100%);
    }

    &:after {
      content: '';
      position: absolute;

      left: 50%;
      bottom: 0;
      transform: translate(-50%, 0);

      width: 0;
      height: 0;

      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-bottom: 5px solid rgba(0, 0, 0, 0.8);
    }
  }

  svg {
    pointer-events: none;
    font-size: 24px;
    color: #636366;
  }
`;

export const Title = styled.div`
  font-size: 24px;
  font-weight: 500;
  color: #1c1c1e;

  text-transform: capitalize;
`;

export const Total = styled.div`
  font-size: 16px;
  font-weight: 500;
  color: #8e8e93;
`;
