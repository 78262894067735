import { useContext, useRef } from 'react';

import DetailsContext from '../DetailsContext';
import AlphabeticFilter from './AlphabeticFilter';
import Detail from './Detail';
import { Container, Header, Title, Content, Group } from './styles';

const List = () => {
  const letterRefs = useRef([]);

  const { data, addDetailToLodging } = useContext(DetailsContext);

  const handleChangeFilter = (value) => {
    const groupToAttach = letterRefs.current.find(
      ({ dataset }) => dataset.letter === value
    );

    if (!groupToAttach) return;
    groupToAttach.scrollIntoView();
  };

  const handleClickAdd = (event) => {
    const { value } = event.target;

    addDetailToLodging(Number(value));
  };

  const getAvailableLetters = () => {
    const letters = data.map(({ details, letter }) => {
      const isAvailable = details.find((detail) => detail.lodging === null);

      if (isAvailable) {
        return letter;
      }

      return '';
    });

    // Removing empty strings
    const lettersFormatted = letters.filter((letter) => letter);

    return lettersFormatted;
  };

  return (
    <Container>
      <AlphabeticFilter
        availableLetters={getAvailableLetters()}
        onChange={handleChangeFilter}
      />
      <Content>
        <Header>
          <Title>Detalhes</Title>
        </Header>

        {data.map(({ letter, details }, index) => (
          <Group
            key={letter}
            ref={(elRef) => {
              letterRefs.current[index] = elRef;
            }}
            data-letter={letter}
          >
            {details.map(
              (detail) =>
                !detail.lodging && (
                  <Detail
                    key={detail.id}
                    data={detail}
                    onClick={handleClickAdd}
                  />
                )
            )}
          </Group>
        ))}
      </Content>
    </Container>
  );
};

export default List;
