import styled from 'styled-components';

export const Container = styled.label`
  display: flex;
  align-items: center;

  cursor: pointer;
`;

export const Input = styled.input`
  display: none;

  &:checked + div {
    border: 2px solid #007aff;

    &:after {
      background-color: #007aff;
    }
  }
`;

export const Toggle = styled.div`
  position: relative;

  display: inline-block;

  width: 20px;
  height: 20px;
  border-radius: 50%;

  margin-right: 5px;

  border: 1px solid #e0e0e0;

  transition: border 200ms linear;

  &:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;

    width: 12px;
    height: 12px;

    border-radius: 50%;

    background-color: #ffffff;

    transform: translate(-50%, -50%);

    transition: background-color 200ms linear;
  }
`;

export const Label = styled.span``;
