import yup from '~/libs/yup';

export const createSchema = yup.object({
  name: yup.string().required().label('Nome'),
  email: yup.string().email().required().label('E-mail'),
  login: yup.string().required().label('Usuário'),
  password: yup.string().required().label('Senha'),
  password_confirmation: yup
    .string()
    .required()
    .equalTo(yup.ref('password'))
    .label('Confirmar senha'),
});

export const updateSchema = yup.object({
  name: yup.string().required().label('Nome'),
  email: yup.string().email().required().label('E-mail'),
  login: yup.string().required().label('Usuário'),
  password: yup.string().label('Senha'),
  password_confirmation: yup
    .string()
    .equalTo(yup.ref('password'))
    .label('Confirmar senha'),
});
