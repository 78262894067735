import styled from 'styled-components';

export const Container = styled.div`
  height: 100%;
  overflow-y: scroll;

  padding: 30px;
`;

export const Actions = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  margin-bottom: 30px;
`;
