import styled from 'styled-components';

import FormHelperText from '@material-ui/core/FormHelperText';
import TextField from '@material-ui/core/TextField';

export const Container = styled.div``;

export const Field = styled(TextField)`
  label.Mui-focused:not(.Mui-error) {
    color: #007aff;
  }

  .MuiInput-underline:after {
    border-bottom-color: #007aff;
  }
`;

export const ErrorMessage = styled(FormHelperText)``;
