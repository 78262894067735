import { useCallback, useEffect, useRef, useState } from 'react';

import { addDays } from 'date-fns';
import { GiMoneyStack } from 'react-icons/gi';
import { HiOutlineBriefcase } from 'react-icons/hi';
import { MdAdd, MdPersonOutline } from 'react-icons/md';
import { useParams, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import Button from '~/components/Button';
import Header from '~/components/Header';
import useWebService from '~/hooks/useWebService';
import PrivateLayout from '~/layouts/Private';
import masks from '~/utils/masks';

import Annotation from './Annotation';
import AnnotationForm from './AnnotationForm';
import Interest from './Interest';
import InterestForm from './InterestForm';
import PhasesForm from './PhasesForm';
import PurposeForm from './PurposeForm';
import StatusForm from './StatusForm';
import {
  Container,
  Contact,
  ContactLink,
  Attendance,
  AttendanceTitle,
  AttendanceContent,
  AttendanceInfo,
  AttendanceButton,
  AttendanceStatus,
  AttendanceActions,
  DetailContainer,
  GridInfo,
  GridTitle,
  GridButton,
} from './styles';
import TransferForm from './TransferForm';
import ValueForm from './ValueForm';

const INITIAL_LEAD = {
  name: '',
};

const INITIAL_LEAD_ATTENDANCE = null;
const INITIAL_LEAD_ANNOTATIONS = [];
const INITIAL_LEAD_INTERESTS = [];

const LeadDetail = () => {
  const { id, businessId } = useParams();
  const history = useHistory();
  const ws = useWebService();
  const phasesFormRef = useRef();
  const statusFormRef = useRef();
  const valueFormRef = useRef();
  const annotationFormRef = useRef();
  const interestFormRef = useRef();
  const transferFormRef = useRef();
  const purposeFormRef = useRef();

  const [lead, setLead] = useState(INITIAL_LEAD);
  const [leadAttendance, setLeadAttendance] = useState(INITIAL_LEAD_ATTENDANCE);
  const [leadAnnotations, setLeadAnnotations] = useState(
    INITIAL_LEAD_ANNOTATIONS
  );
  const [leadInterests, setLeadInterests] = useState(INITIAL_LEAD_INTERESTS);

  const getLeadDetail = useCallback(async () => {
    try {
      const response = await ws.get('atendimento/retornaClienteDetalhes.php', {
        params: { id, atendimento_id: businessId },
      });

      if (response.data.result) {
        const { dados } = response.data;
        setLead(dados.cliente);
        setLeadAttendance(dados.atendimento);
        setLeadAnnotations(dados.anotacoes);
        setLeadInterests(dados.interesses);
      } else {
        toast.error(`Erro ao buscar detalhes do lead ${id}...`);
        setLead(INITIAL_LEAD);
      }
    } catch (error) {
      toast.error(`Erro ao buscar detalhes do lead ${id}...`);
      history.goBack();
    }
  }, [id, businessId]);

  useEffect(() => {
    getLeadDetail();
  }, [id, businessId]);

  const handleOpenPhasesForm = () => {
    phasesFormRef.current.setData({
      etapa: leadAttendance.etapa,
      atendimento_id: leadAttendance.id,
    });
  };
  const handleOpenStatusForm = () => {
    statusFormRef.current.setData({
      status: leadAttendance.status,
      atendimento_id: leadAttendance.id,
    });
  };
  const handleOpenValueForm = () => {
    valueFormRef.current.setData({
      valor_total: leadAttendance.valor_total || '0',
      atendimento_id: leadAttendance.id,
    });
  };
  const handleOpenAnnotationForm = () => {
    annotationFormRef.current.setData({
      anotacao: '',
      atendimento_id: leadAttendance.id,
    });
  };
  const handleOpenInterestForm = () => {
    const checkinDate = addDays(new Date(), 1);
    interestFormRef.current.setData({
      hospedagem: '',
      checkin: checkinDate,
      checkout: addDays(checkinDate, 1),
      adultos: 1,
      criancas: 0,
      infantil: 0,
      mensagem: '',
      atendimento_id: leadAttendance.id,
    });
  };
  const handleOpenTransferForm = () => {
    transferFormRef.current.setData({
      atendente_novo_id: leadAttendance.atendente_id,
      atendente_atual: leadAttendance.atendente_nome,
      atendimento_id: leadAttendance.id,
    });
  };
  const handleOpenPurposeForm = () => {
    purposeFormRef.current.setData({
      proposito: leadAttendance.proposito,
      atendimento_id: leadAttendance.id,
    });
  };

  const handleOnCloseForm = (reload) => {
    if (reload) {
      getLeadDetail();
    }
  };

  return (
    <PrivateLayout>
      <Container>
        <Header
          backButton
          title={`Lead - ${lead.nome || ''}`}
          total={leadInterests.length}
        />
        <Contact>
          Contatos:{' '}
          {lead.telefone && (
            <ContactLink
              target="_blank"
              href={`https://api.whatsapp.com/send?phone=55${lead.telefone.replace(
                /\D/g,
                ''
              )}`}
            >
              {masks.phoneOrCellphone(lead.telefone)}
            </ContactLink>
          )}{' '}
          | {lead.email}
        </Contact>

        {leadAttendance && (
          <>
            <Attendance>
              <AttendanceTitle>Detalhes do Negócio</AttendanceTitle>
              <AttendanceContent>
                <AttendanceInfo>
                  <div>
                    <div className="mb-5">
                      <AttendanceButton onClick={handleOpenPurposeForm}>
                        <HiOutlineBriefcase />
                      </AttendanceButton>
                      <span>
                        <strong>Propósito: </strong>
                        {leadAttendance.proposito || '-'}
                      </span>
                    </div>
                    <div className="mb-5">
                      <AttendanceButton onClick={handleOpenTransferForm}>
                        <MdPersonOutline />
                      </AttendanceButton>
                      <span>
                        <strong>Atendente: </strong>
                        {leadAttendance.atendente_nome || '-'}
                      </span>
                    </div>
                    <div>
                      <AttendanceButton onClick={handleOpenValueForm}>
                        <GiMoneyStack />
                      </AttendanceButton>
                      <span>
                        <strong>Valor R$: </strong>
                        {leadAttendance.valor_total
                          ? masks.currency(leadAttendance.valor_total)
                          : '-'}
                      </span>
                    </div>
                  </div>
                  <AttendanceStatus
                    etapa={leadAttendance.etapa}
                    status={leadAttendance.status}
                  >
                    <span className="etapa">{leadAttendance.etapa}</span>
                    <span className="status">{leadAttendance.status}</span>
                  </AttendanceStatus>
                </AttendanceInfo>
                <AttendanceActions>
                  <Button label="Etapa" onClick={handleOpenPhasesForm} />
                  <Button label="Status" onClick={handleOpenStatusForm} />
                  {/* <Button label="Valor" onClick={handleOpenValueForm} /> */}
                </AttendanceActions>
              </AttendanceContent>
            </Attendance>

            <DetailContainer>
              <GridInfo>
                <GridTitle>
                  Interesses
                  <GridButton onClick={handleOpenInterestForm}>
                    <MdAdd />
                  </GridButton>
                </GridTitle>
                {leadInterests.map((interest) => (
                  <Interest key={interest.id} data={interest} />
                ))}
              </GridInfo>

              <GridInfo>
                <GridTitle>
                  Anotações
                  <GridButton onClick={handleOpenAnnotationForm}>
                    <MdAdd />
                  </GridButton>
                </GridTitle>
                {leadAnnotations.map((annotation) => (
                  <Annotation key={annotation.id} data={annotation} />
                ))}
              </GridInfo>
            </DetailContainer>

            <StatusForm ref={statusFormRef} onClose={handleOnCloseForm} />
            <PhasesForm ref={phasesFormRef} onClose={handleOnCloseForm} />
            <ValueForm ref={valueFormRef} onClose={handleOnCloseForm} />
            <InterestForm ref={interestFormRef} onClose={handleOnCloseForm} />
            <AnnotationForm
              ref={annotationFormRef}
              onClose={handleOnCloseForm}
            />
            <TransferForm ref={transferFormRef} onClose={handleOnCloseForm} />
            <PurposeForm ref={purposeFormRef} onClose={handleOnCloseForm} />
          </>
        )}
      </Container>
    </PrivateLayout>
  );
};

export default LeadDetail;
