import styled, { css } from 'styled-components';

export const Container = styled.div`
  height: 40px;
  width: 40px;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Content = styled.button`
  position: relative;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;

  height: 32px;
  width: 32px;

  border-radius: 50%;

  border: 1px solid #eeeeee;

  background-color: #ffffff;
  transition: height 200ms linear, width 200ms linear, border-color 200ms linear,
    background-color 200ms linear;

  svg {
    pointer-events: none;

    font-size: 16px;
    transition: color 200ms linear, font-size 200ms linear;

    ${({ colorIcon }) => {
      if (colorIcon.includes('#'))
        return css`
          color: ${colorIcon};
        `;

      return css`
        color: #48484a;
      `;
    }}
  }

  &:hover {
    height: 40px;
    width: 40px;

    ${({ color, disabled }) => {
      if (color === 'default' && !disabled)
        return css`
          border-color: #007aff;
          background-color: #007aff;
        `;

      if (color === 'danger' && !disabled)
        return css`
          border-color: #ff3b30;
          background-color: #ff3b30;
        `;

      return css`
        border-color: #8e8e93;
        background-color: #8e8e93;
      `;
    }}

    svg {
      font-size: 22px;
      color: #ffffff;
    }

    ${({ tooltip }) => css`
      :before {
        content: ${`'${tooltip}'`};
        pointer-events: none;
        position: absolute;
        z-index: 4;

        bottom: 0;
        right: 50%;
        padding: 5px 7px;

        border-radius: 3px;
        color: #ffffff;
        background-color: rgba(0, 0, 0, 0.8);
        transform: translate(50%, 100%);
      }

      &:after {
        content: '';
        pointer-events: none;
        position: absolute;
        z-index: 4;

        left: 50%;
        bottom: 0;
        transform: translate(-50%, 0);

        width: 0;
        height: 0;

        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-bottom: 5px solid rgba(0, 0, 0, 0.8);
      }
    `}
  }
`;
