import yup from '~/libs/yup';

export const createSchema = yup.object({
  icon_name: yup.string().required().label('Ícone'),
  is_boolean: yup.bool().required().label('Tipo'),
  name: yup.string().required().label('Nome'),
});

export const updateSchema = yup.object({
  icon_name: yup.string().required().label('Ícone'),
  is_boolean: yup.bool().required().label('Tipo'),
  name: yup.string().required().label('Nome'),
});
