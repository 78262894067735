import { useCallback, useEffect, useRef, useState } from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';
import MuiAutocomplete from '@material-ui/lab/Autocomplete';
import { useField } from '@unform/core';
import PropTypes from 'prop-types';

import { Container, Field, ErrorMessage } from './styles';

const Autocomplete = ({
  label,
  name,
  fullWidth,
  options,
  optionKeyValue,
  optionKeyLabel,
  onChange,
  loading,
  onInputChange,
  ...rest
}) => {
  const autocompleteRef = useRef(null);

  const [selectedValue, setSelectedValue] = useState(null);

  // defaultValue
  const { fieldName, registerField, error, defaultValue } = useField(name);

  useEffect(() => {
    if (autocompleteRef.current)
      registerField({
        name: fieldName,
        ref: autocompleteRef.current,
        path: 'value',
        getValue: (ref) => {
          return ref.value ? ref.value[optionKeyValue] : null;
        },
        setValue: (ref, value) => {
          setSelectedValue(value);
          ref.value = value;
        },
      });
  }, [fieldName, registerField]);

  // To use masks
  const handleChange = useCallback((event, newValue) => {
    event.persist();

    const value = newValue;

    setSelectedValue(value);
    autocompleteRef.current.value = value;

    if (onChange) {
      onChange(value);
    }
  }, []);

  useEffect(() => {
    if (defaultValue && options.length > 0) {
      setSelectedValue(defaultValue);
      autocompleteRef.current.value = defaultValue;

      if (onChange) {
        onChange(defaultValue);
      }
    }
  }, [defaultValue, options]);

  return (
    <Container className="root-autocomplete">
      <MuiAutocomplete
        ref={autocompleteRef}
        value={selectedValue}
        getOptionSelected={(option, value) =>
          option[optionKeyValue] === value[optionKeyValue]
        }
        getOptionLabel={(option) => option[optionKeyLabel]}
        onChange={handleChange}
        onInputChange={onInputChange}
        options={options}
        renderInput={(params) => (
          <Field
            {...params}
            label={label}
            type="text"
            name={name}
            fullWidth={fullWidth}
            error={!!error}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
        {...rest}
      />
      <ErrorMessage>{error}</ErrorMessage>
    </Container>
  );
};

Autocomplete.defaultProps = {
  label: '',
  fullWidth: true,
  options: [],
  optionKeyValue: 'id',
  optionKeyLabelSeparator: ' - ',
  onChange: () => {},
  loading: false,
  onInputChange: () => {},
};

Autocomplete.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  fullWidth: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.object),
  optionKeyValue: PropTypes.string,
  optionKeyLabel: PropTypes.string.isRequired,
  optionKeyLabelSeparator: PropTypes.string,
  onChange: PropTypes.func,
  loading: PropTypes.bool,
  onInputChange: PropTypes.func,
};

export default Autocomplete;
