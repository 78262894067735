import styled from 'styled-components';

export const Container = styled.div`
  position: relative;

  display: grid;
  grid-template-columns: 50px 1fr 1fr 50px;

  max-width: 500px;

  padding: 15px;
  margin-bottom: 7px;

  border-radius: 4px;

  border: 1px solid #fafafa;
  background-color: #fafafa;

  .root-input {
    max-width: 100px;
  }
`;

export const IconBox = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;

  margin-right: 10px;
`;

export const Name = styled.span`
  display: flex;
  align-items: center;
`;

export const Value = styled.span``;

export const ButtonDelete = styled.button`
  border: none;
  background: none;

  &:hover svg {
    color: #ff3b30;
  }

  svg {
    pointer-events: none;
    font-size: 24px;

    color: #c7c7cc;

    transition: color 200ms linear;
  }
`;
