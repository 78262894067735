import { useEffect, useRef } from 'react';

import { useField } from '@unform/core';
import PropTypes from 'prop-types';

// Style
import Radio from './Radio';
import { Container, Group, Label, Error } from './styles';

const RadioGroup = ({ direction, items, name, label, onChange, ...rest }) => {
  const radiosRef = useRef([null]);

  const { fieldName, registerField, error, defaultValue } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      path: 'value',
      ref: radiosRef.current,
      getValue(refs) {
        const checked = refs.find((ref) => ref.checked);

        return checked ? checked.value : null;
      },
      setValue(refs, value) {
        const item = refs.find(
          (ref) => ref.value.toString() === value.toString()
        );

        if (item) {
          item.checked = true;
        }
      },
    });
  }, [fieldName, registerField, rest.checked]);

  useEffect(() => {
    if (radiosRef.current && defaultValue !== undefined) {
      const radioRef = radiosRef.current.find(
        (ref) => ref.value.toString() === defaultValue.toString()
      );

      radioRef.checked = true;
      onChange({ value: radioRef.value, checked: radioRef.checked });
    }
  }, [defaultValue]);

  const handleChange = () => {
    const { value, checked } = radiosRef.current.find((ref) => ref.checked);

    onChange({ value, checked });
  };

  return (
    <Container className="root-checkbox">
      {label && <Label>{label}</Label>}
      <Group direction={direction}>
        {items.map((item, index) => (
          <Radio
            key={item.value}
            inputRef={(elRef) => {
              radiosRef.current[index] = elRef;
            }}
            name={fieldName}
            label={item.label}
            value={item.value}
            onChange={handleChange}
            defaultChecked={defaultValue === item.value}
          />
        ))}
      </Group>
      {error && <Error>{error}</Error>}
    </Container>
  );
};

RadioGroup.defaultProps = {
  label: '',
  direction: 'column',
  onChange: () => {},
};

RadioGroup.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  direction: PropTypes.oneOf(['column', 'row']),
  onChange: PropTypes.func,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool,
      ]),
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool,
      ]),
    })
  ).isRequired,
};

export default RadioGroup;
