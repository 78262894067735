import { createActions, createReducer } from 'reduxsauce';

export const { Types, Creators } = createActions({
  setLodgingTotalImages: ['total'],
});

const INITIAL_STATE = 0;

const setLodgingTotalImages = (state, { total }) => Number(total);

export default createReducer(INITIAL_STATE, {
  [Types.SET_LODGING_TOTAL_IMAGES]: setLodgingTotalImages,
});
