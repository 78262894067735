import styled, { css } from 'styled-components';

export const Container = styled.div`
  padding: 15px 0;

  &:not(:last-of-type) {
    border-bottom: 1px solid #eee;
  }
`;

export const Title = styled.div`
  font-size: 18px;
  font-weight: 500;
  color: #1c1c1e;

  margin-bottom: 20px;
`;

export const Content = styled.div`
  display: grid;
  grid-template-columns: 1fr;

  margin-bottom: 15px;
`;

export const InfoContainer = styled.div`
  display: flex;
`;

export const PersonsContainer = styled.div`
  display: flex;
  margin-bottom: 15px;
`;

export const InfoContent = styled.div`
  &:not(:last-of-type) {
    margin-right: 15px;
  }
`;

export const Label = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: #2c2c2e;

  margin-bottom: 2px;
`;

export const Value = styled.div`
  color: #3a3a3c;

  ${({ multiline }) =>
    multiline &&
    css`
      white-space: pre-wrap;
    `}
`;
