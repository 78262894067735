export const etapaStyles = {
  colors: {
    default: '#000000',
    Prospecção: '#BD35D1',
    Sondagem: '#F7C325',
    Orçamento: '#788996',
    Negociando: '#2D88DA',
    Pagamento: '#1BAE9F',
  },
  backgrounds: {
    default: '#DDDDDD',
    Prospecção: '#F1D4F5',
    Sondagem: '#FDF2D1',
    Orçamento: '#E3E6E9',
    Negociando: '#D3E7F7',
    Pagamento: '#CFEEEB',
  },
  borders: {
    default: '1px solid #AAAAAA',
    Prospecção: '1px solid #BD35D1',
    Sondagem: '1px solid #F7C325',
    Orçamento: '1px solid #788996',
    Negociando: '1px solid #2D88DA',
    Pagamento: '1px solid #1BAE9F',
  },
};

export const statusStyles = {
  colors: {
    default: '#000000',
    Aberto: '#000000',
    Ganho: '#FFFFFF',
    Perdido: '#FFFFFF',
  },
  backgrounds: {
    default: '#DDDDDD',
    Aberto: '#FFFFFF',
    Ganho: '#1BAE9F',
    Perdido: '#D3455B',
  },
};
