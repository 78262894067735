import PropTypes from 'prop-types';
import * as FaIcons from 'react-icons/fa';

import { Container, IconBox, Label } from './styles';

const Detail = ({ data, onClick }) => {
  const { id, icon_name, name } = data;

  const Icon = FaIcons[icon_name];

  return (
    <Container type="button" value={id} onClick={onClick}>
      <IconBox>
        <Icon />
      </IconBox>
      <Label>{name}</Label>
    </Container>
  );
};

Detail.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    icon_name: PropTypes.string.isRequired,
  }).isRequired,
  onClick: PropTypes.func.isRequired,
};

export default Detail;
