import { useState } from 'react';

import PropTypes from 'prop-types';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import CircleButton from '~/components/CircleButton';
import api from '~/services/api';

const PublicButton = ({ data }) => {
  const { id } = useParams();

  const { name, order, is_public } = data;
  const imageId = data.id;

  const [isPublic, setIsPublic] = useState(is_public);

  const textIsPublic = isPublic ? 'privada' : 'pública';

  const handleClickTogglePublic = async () => {
    try {
      const formData = new FormData();
      formData.append('is_public', !isPublic);
      formData.append('name', name);
      formData.append('order', order);

      const res = await api.put(`/lodgings/${id}/images/${imageId}`, formData);

      if (res.status === 200) {
        setIsPublic(!isPublic);
      }

      toast.success(`Sucesso ao tornar a imagem ${textIsPublic}!`);
    } catch (error) {
      if (error.response.data.error) {
        toast.error(error.response.data.error);
      } else {
        toast.error(`Erro ao tornar a imagem ${textIsPublic}!`);
      }
    }
  };

  const getIcon = () => {
    if (isPublic) return <FaEye />;

    return <FaEyeSlash />;
  };

  return (
    <CircleButton
      tooltip={`Definir imagem como ${textIsPublic}!`}
      icon={getIcon()}
      onClick={handleClickTogglePublic}
      colorIcon={isPublic ? '#35C759' : '#8E8E93'}
    />
  );
};

PublicButton.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    order: PropTypes.number.isRequired,
    is_public: PropTypes.bool.isRequired,
  }).isRequired,
};

export default PublicButton;
