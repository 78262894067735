import { useState, useImperativeHandle, forwardRef } from 'react';

import Menu from '@material-ui/core/Menu';

import { MenuItem, Button, Link } from './styles';

const DropMenuLodgings = ({ ...rest }, ref) => {
  const [anchor, setAnchor] = useState(null);
  const [data, setData] = useState({ id: 0 });

  useImperativeHandle(ref, () => ({
    setAnchor: (target) => setAnchor(target.currentTarget),
    setData: (values) => setData(values),
  }));

  const handleClose = () => setAnchor(null);

  const handleClickEdit = () => rest.onEdit();

  const { id } = data;

  return (
    <Menu
      id="simple-menu"
      anchorEl={anchor}
      keepMounted
      open={Boolean(anchor)}
      onClose={handleClose}
    >
      <MenuItem onClick={handleClose}>
        <Button type="button" onClick={handleClickEdit}>
          Editar hospedagem
        </Button>
      </MenuItem>
      <MenuItem onClick={handleClose}>
        <Link
          to={{
            pathname: `/hospedagens/${id}/imagens`,
            state: data,
          }}
        >
          Imagens
        </Link>
      </MenuItem>
      <MenuItem onClick={handleClose}>
        <Link
          to={{
            pathname: `/hospedagens/${id}/detalhes`,
            state: data,
          }}
        >
          Detalhes
        </Link>
      </MenuItem>
      {/* <MenuItem onClick={handleClose}>
        <Link
          to={{
            pathname: `/hospedagens/${id}/tipo-pensao`,
            state: data,
          }}
        >
          Tipos de pensão
        </Link>
      </MenuItem>
      <MenuItem onClick={handleClose}>
        <Link
          to={{
            pathname: `/hospedagens/${id}/tipo-tarifario`,
            state: data,
          }}
        >
          Tipos de tarifário
        </Link>
      </MenuItem> */}
    </Menu>
  );
};

export default forwardRef(DropMenuLodgings);
