import {
  forwardRef,
  useContext,
  useImperativeHandle,
  useState,
  useRef,
} from 'react';

import Input from '~/components/Material/Input';
import ModalDeleteContext from '~/components/ModalDelete/ModalContext';
import SideForm from '~/components/SideForm';
import DangerZone from '~/components/SideForm/DangerZone';
import FixedControl from '~/components/SideForm/FixedControl';
import Section from '~/components/SideForm/Section';
import Title from '~/components/SideForm/Title';
import api from '~/services/api';
import yupValidate from '~/utils/yupValidate';
import { createSchema, updateSchema } from '~/validators/user.schemas';

import { Content } from './styles';

const Form = ({ ...rest }, ref) => {
  const { openModalDelete } = useContext(ModalDeleteContext);

  const formRef = useRef();
  const buttonRef = useRef();
  const [user, setUser] = useState({});

  useImperativeHandle(
    ref,
    () => ({
      setData: (value) => setUser(value),
    }),
    []
  );

  const closeForm = (reload) => {
    formRef.current.reset();
    formRef.current.setErrors({});
    setUser({});
    rest.onClose(reload);
  };

  const storeUser = async (data) => {
    try {
      buttonRef.current.start();

      // Dados básicos
      const response = await api.post('/users', data);

      if (response.status === 201) {
        buttonRef.current.finish({
          type: 'success',
          callback: () => closeForm(true),
        });
      }
      return 'response';
    } catch (error) {
      // if (error.response) {
      //   const { status } = error.response;

      //   if (status === 400) {
      //     return error;
      //   }
      // }

      buttonRef.current.finish({
        type: 'error',
        autoReset: true,
        timeReset: 2,
      });
      return error;
    }
  };

  const updateUser = async (data) => {
    try {
      buttonRef.current.start();

      // Dados básicos
      const response = await api.put(`/users/${user.id}`, data);

      if (response.status === 200) {
        buttonRef.current.finish({
          type: 'success',
          callback: () => closeForm(true),
        });
      }
      return 'response';
    } catch (error) {
      // if (error.response) {
      //   const { status } = error.response;

      //   if (status === 400) {
      //     return error;
      //   }
      // }

      buttonRef.current.finish({
        type: 'error',
        autoReset: true,
        timeReset: 2,
      });
      return error;
    }
  };

  const handleSubmit = async (formData) => {
    // Limpa os erros
    formRef.current.setErrors({});

    const validationSchema = user.id ? updateSchema : createSchema;
    // Valida os campos
    const { success, data, errors } = await yupValidate(validationSchema, {
      ...formData,
    });

    if (!success) {
      return formRef.current.setErrors(errors);
    }

    if (user.id) {
      return updateUser(data);
    }

    return storeUser(data);
  };

  const handleOnClose = () => closeForm(false);

  const handleDelete = () => {
    openModalDelete({
      url: `/users/${user.id}`,
      title: 'Deseja deletar o usuário?',
      onDelete: () => closeForm(true),
      autoClose: true,
      messages: {
        success: 'Sucesso ao deletar o usuário!',
        error: 'Erro ao deletar o usuário...',
      },
    });
  };

  return (
    <SideForm
      formRef={formRef}
      data={user}
      onClose={handleOnClose}
      onSubmit={handleSubmit}
    >
      <Content>
        <Title
          label={user.id ? `Editando usuário - ${user.id}` : 'Nova usuário'}
        />
        <Section>
          <Input label="Nome" name="name" />
          <Input type="email" label="E-mail" name="email" />
          <Input label="Login" name="login" />
          <Input
            type="password"
            label={user.id ? 'Nova senha (opcional)' : 'Senha'}
            name="password"
          />
          <Input
            type="password"
            label={
              user.id ? 'Confirmar nova senha (opcional)' : 'Confirmar senha'
            }
            name="password_confirmation"
          />
        </Section>
        {user.id && (
          <DangerZone message="Deletar usuário" onClick={handleDelete} />
        )}
        <FixedControl buttonRef={buttonRef} />
      </Content>
    </SideForm>
  );
};

export default forwardRef(Form);
