import { all, takeLatest } from 'redux-saga/effects';

import { Types as AuthTypes } from '~/store/ducks/auth';

import { authLogin, authSetToken } from './auth';

function* rootSaga() {
  return yield all([
    takeLatest('persist/REHYDRATE', authSetToken),
    takeLatest(AuthTypes.AUTH_LOGIN_REQUEST, authLogin),
  ]);
}

export default rootSaga;
