import {
  forwardRef,
  useContext,
  useImperativeHandle,
  useState,
  useRef,
} from 'react';

import Input from '~/components/Material/Input';
import ModalDeleteContext from '~/components/ModalDelete/ModalContext';
import RadioGroup from '~/components/RadioGroup';
import SideForm from '~/components/SideForm';
import DangerZone from '~/components/SideForm/DangerZone';
import FixedControl from '~/components/SideForm/FixedControl';
import Section from '~/components/SideForm/Section';
import SubTitle from '~/components/SideForm/SubTitle';
import Title from '~/components/SideForm/Title';
import api from '~/services/api';
import yupValidate from '~/utils/yupValidate';
import { createSchema, updateSchema } from '~/validators/detail.schemas';

import GridIcons from './GridIcons';
import { Content } from './styles';

const Form = ({ ...rest }, ref) => {
  const { openModalDelete } = useContext(ModalDeleteContext);

  const formRef = useRef();
  const buttonRef = useRef();
  const [detail, setDetail] = useState({});

  useImperativeHandle(
    ref,
    () => ({
      setData: (value) => setDetail(value),
    }),
    []
  );

  const closeForm = (reload) => {
    formRef.current.setErrors({});
    formRef.current.reset();
    setDetail({});
    rest.onClose(reload);
  };

  const storeDetail = async (data) => {
    try {
      buttonRef.current.start();

      // Dados básicos
      const response = await api.post('/details', data);

      if (response.status === 201) {
        buttonRef.current.finish({
          type: 'success',
          callback: () => closeForm(true),
        });
      }
      return 'response';
    } catch (error) {
      // if (error.response) {
      //   const { status } = error.response;

      //   if (status === 400) {
      //     return error;
      //   }
      // }

      buttonRef.current.finish({
        type: 'error',
        autoReset: true,
        timeReset: 2,
      });

      return error;
    }
  };

  const updateDetail = async (data) => {
    try {
      buttonRef.current.start();
      // Dados básicos
      const response = await api.put(`/details/${detail.id}`, data);

      if (response.status === 200) {
        buttonRef.current.finish({
          type: 'success',
          callback: () => closeForm(true),
        });
      }
      return 'response';
    } catch (error) {
      // if (error.response) {
      //   const { status } = error.response;

      //   if (status === 400) {
      //     return error;
      //   }
      // }

      buttonRef.current.finish({
        type: 'error',
        autoReset: true,
        timeReset: 2,
      });

      return error;
    }
  };

  const handleSubmit = async (formData) => {
    // Limpa os erros
    formRef.current.setErrors({});

    const validationSchema = detail.id ? updateSchema : createSchema;
    // Valida os campos
    const { success, data, errors } = await yupValidate(validationSchema, {
      ...formData,
    });

    if (!success) {
      return formRef.current.setErrors(errors);
    }

    if (detail.id) {
      return updateDetail(data);
    }

    return storeDetail(data);
  };

  const handleOnClose = () => closeForm(false);

  const handleDelete = () => {
    openModalDelete({
      url: `/details/${detail.id}`,
      title: 'Deseja deletar o detalhe?',
      onDelete: () => closeForm(true),
      autoClose: true,
      messages: {
        success: 'Sucesso ao deletar o detalhe!',
        error: 'Erro ao deletar o detalhe...',
      },
    });
  };

  return (
    <SideForm
      formRef={formRef}
      data={detail}
      onClose={handleOnClose}
      onSubmit={handleSubmit}
    >
      <Content>
        <Title
          label={detail.id ? `Editando detalhe - ${detail.id}` : 'Novo detalhe'}
        />
        <Section>
          <SubTitle label="Dados básicos" />
          <Input label="Nome" name="name" />
          <RadioGroup
            label="Tipo"
            name="is_boolean"
            items={[
              { value: 'true', label: 'Sim ou não' },
              { value: 'false', label: 'Quantitativo' },
            ]}
          />
        </Section>
        <Section>
          <SubTitle label="Ícone" />
          <GridIcons />
        </Section>
        {detail.id && (
          <DangerZone message="Deletar detalhe" onClick={handleDelete} />
        )}
        <FixedControl buttonRef={buttonRef} />
      </Content>
    </SideForm>
  );
};

export default forwardRef(Form);
