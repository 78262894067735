import styled, { css } from 'styled-components';

import { etapaStyles, statusStyles } from '~/styles/configs/attendance';

const ButtonIcon = styled.span`
  cursor: pointer;
  user-select: none;

  display: flex;
  align-items: center;
  justify-content: center;

  height: 26px;
  width: 26px;

  border-radius: 4px;
  background-color: #0073f0;

  svg {
    pointer-events: none;

    color: #ffffff;
  }
`;

export const Container = styled.div`
  height: 100%;
  padding: 30px;

  overflow-y: scroll;
`;

export const Contact = styled.div`
  color: #007aff;
`;
export const ContactLink = styled.a`
  color: inherit;
`;

export const Attendance = styled.div`
  margin-top: 15px;
  padding: 20px 0;
  border-bottom: 1px solid #eee;
`;
export const AttendanceTitle = styled.div`
  font-size: 22px;
  font-weight: 500;
  color: #1c1c1e;
  text-align: center;
  margin-bottom: 5px;
`;
export const AttendanceContent = styled.div`
  display: flex;
`;
export const AttendanceInfo = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;

  > div {
    > div {
      display: flex;
      align-items: center;
    }

    .mb-5 {
      margin-bottom: 5px;
    }
  }
`;
export const AttendanceButton = styled(ButtonIcon)`
  float: left;
  margin-right: 5px;
`;
export const AttendanceStatus = styled.div`
  display: flex;
  align-items: center;

  .etapa {
    border-radius: 10px;
    padding: 4px 8px;

    ${({ etapa }) => {
      if (etapaStyles.colors[etapa]) {
        return css`
          color: ${etapaStyles.colors[etapa]};
          background-color: ${etapaStyles.backgrounds[etapa]};
          border: ${etapaStyles.borders[etapa]};
        `;
      }

      return css`
        color: ${etapaStyles.colors.default};
        background-color: ${etapaStyles.backgrounds.default};
        border: ${etapaStyles.borders.default};
      `;
    }}
  }

  .status {
    margin-left: 5px;
    border-radius: 10px;
    padding: 4px 8px;

    ${({ status }) => {
      if (statusStyles.colors[status]) {
        return css`
          color: ${statusStyles.colors[status]};
          background-color: ${statusStyles.backgrounds[status]};
        `;
      }

      return css`
        color: ${statusStyles.colors.default};
        background-color: ${statusStyles.backgrounds.default};
      `;
    }}
  }
`;
export const AttendanceActions = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  > button + button {
    margin-left: 5px;
  }
`;

export const DetailContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px;

  margin-top: 15px;
`;

export const GridTitle = styled.div`
  font-size: 22px;
  font-weight: 500;
  color: #1c1c1e;
  text-align: center;
`;

export const GridButton = styled(ButtonIcon)`
  float: right;
`;

export const GridInfo = styled.div``;
