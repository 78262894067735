import styled from 'styled-components';

import devices, { sizes } from '~/styles/configs/devices';

export const Container = styled.table`
  border-collapse: collapse;
  width: 100%;

  @media ${`${devices.mobileS} and (max-width: ${sizes.tablet})`} {
    table-layout: fixed;
    margin: 0;
    border: 0;
  }

  @media ${devices.tablet} {
    border-collapse: separate;
    border-spacing: 0 15px;
  }
`;
