import styled, { css } from 'styled-components';

export const Container = styled.div``;

export const Group = styled.div`
  ${({ direction }) => {
    if (direction === 'row') {
      return css`
        display: flex;

        > *:not(:last-child) {
          margin-right: 10px;
        }
      `;
    }

    return css`
      > *:not(:last-child) {
        margin-bottom: 10px;
      }
    `;
  }}
`;

export const Label = styled.div`
  font-size: 12px;
  color: #1c1c1e;

  margin-bottom: 15px;
`;

export const Error = styled.span`
  font-size: 12px;
  color: #ff3b30;
`;
