import styled, { css } from 'styled-components';

import { KeyboardTimePicker } from '@material-ui/pickers';

export const Container = styled.div`
  label.Mui-focused:not(.Mui-error) {
    color: #007aff;
  }

  .MuiInput-underline:after {
    border-bottom-color: #007aff;
  }
`;

export const TimePickerField = styled(KeyboardTimePicker)``;

export const TimePicker = css``;

export const Error = styled.span`
  font-size: 12px;
  color: #ff3b30;
`;
