import PropTypes from 'prop-types';

import formatDate from '~/utils/formatDate';

import { Container, Title, Value } from './styles';

const Annotation = ({ data }) => {
  const { anotacao, atendente_nome, data: date } = data;

  return (
    <Container>
      <Title>
        {atendente_nome} -{' '}
        {formatDate(date, { hour: 'numeric', minute: 'numeric' })}
      </Title>

      <Value>{anotacao}</Value>
    </Container>
  );
};

Annotation.propTypes = {
  data: PropTypes.shape({
    anotacao: PropTypes.string,
    atendente_nome: PropTypes.string,
    data: PropTypes.string,
  }).isRequired,
};

export default Annotation;
