import PropTypes from 'prop-types';

import { Container } from './styles';

const Th = ({ width, align, children }) => {
  return (
    <Container width={width} align={align}>
      {children}
    </Container>
  );
};

Th.defaultProps = {
  width: 'auto',
  align: 'left',
};

Th.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  align: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
};

export default Th;
