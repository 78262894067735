import PropTypes from 'prop-types';
import { MdModeEdit } from 'react-icons/md';

import { Container } from './styles';

const EditButton = ({ onClick }) => {
  return (
    <Container type="button" onClick={onClick}>
      <MdModeEdit />
    </Container>
  );
};

EditButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default EditButton;
