import { useCallback, useEffect, useRef, useState } from 'react';

import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { useField } from '@unform/core';
import pt from 'date-fns/locale/pt';
import PropTypes from 'prop-types';

// Style
import { Container, DatePickerField, Error } from './styles';

const DatePicker = ({
  name,
  label,
  onChange,
  className,
  disableText,
  ...rest
}) => {
  const inputRef = useRef({ value: null });

  const { defaultValue, error, fieldName, registerField } = useField(name);

  const [date, setDate] = useState(defaultValue);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
      setValue: (ref, value) => {
        ref.value = value;
        setDate(value);

        if (onChange) {
          onChange(value);
        }
      },
    });
  }, [fieldName, registerField]);

  // Default value
  useEffect(() => {
    if (defaultValue && inputRef.current) {
      inputRef.current.value = defaultValue;

      if (onChange) {
        onChange(defaultValue);
      }
    }
  }, [defaultValue]);

  // On change
  const handleChange = useCallback(
    (value) => {
      inputRef.current.value = value;

      setDate(value);

      if (onChange) {
        onChange(value);
      }
    },
    [rest]
  );

  return (
    <Container className={`material-date-picker ${className}`}>
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={pt}>
        <DatePickerField
          disableToolbar
          error={!!error}
          format="dd/MM/yyyy"
          fullWidth
          invalidDateMessage="Fomato inválido"
          maxDateMessage="Data não deve ser maior que a máxima."
          minDateMessage="Data não deve ser menor que a mínima."
          margin="normal"
          inputProps={{ disabled: disableText }}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
          label={label}
          name={`picker-${fieldName}`}
          onChange={handleChange}
          value={date}
          variant="inline"
          clearLabel="Limpar"
          cancelLabel="Cancelar"
          {...rest}
        />
      </MuiPickersUtilsProvider>
      {error && <Error>{error}</Error>}
    </Container>
  );
};

DatePicker.defaultProps = {
  onChange: false,
  className: '',
  disableText: false,
};

DatePicker.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  disableText: PropTypes.bool,
};

export default DatePicker;
