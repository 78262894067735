export default [
  'FaAccessibleIcon',
  'FaEnvira',
  'FaFortAwesome',
  'FaFontAwesomeFlag',
  'FaHotjar',
  'FaPagelines',
  'FaServicestack',
  'FaUntappd',
  'FaUsb',
  'FaArchive',
  'FaAppleAlt',
  'FaAmbulance',
  'FaBabyCarriage',
  'FaBaby',
  'FaBacon',
  'FaBed',
  'FaBible',
  'FaBirthdayCake',
  'FaBone',
  'FaBreadSlice',
  'FaBriefcaseMedical',
  'FaCar',
  'FaCity',
  'FaCoffee',
  'FaCocktail',
  'FaConciergeBell',
  'FaDollyFlatbed',
  'FaGasPump',
  'FaFutbol',
  'FaGlassCheers',
  'FaGlassMartiniAlt',
  'FaGlassMartini',
  'FaGolfBall',
  'FaGhost',
  'FaHamburger',
  'FaGuitar',
  'FaHotel',
  'FaHotTub',
  'FaHotdog',
  'FaIceCream',
  'FaLandmark',
  'FaMortarPestle',
  'FaPalette',
  'FaPeopleCarry',
  'FaPizzaSlice',
  'FaPhone',
  'FaPlug',
  'FaPuzzlePiece',
  'FaRobot',
  'FaShoppingCart',
  'FaSkating',
  'FaShoppingBasket',
  'FaShuttleVan',
  'FaStore',
  'FaSwimmingPool',
  'FaToiletPaper',
  'FaToilet',
  'FaUmbrellaBeach',
  'FaTv',
  'FaTshirt',
  'FaVolleyballBall',
  'FaWifi',
  'FaTableTennis',
  'FaTaxi',
];
