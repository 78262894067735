import PropTypes from 'prop-types';

import { Container, Toggle, Input, Label } from './styles';

const Radio = ({ inputRef, value, onChange, defaultChecked, label, name }) => {
  return (
    <Container htmlFor={name + value}>
      <Input
        ref={inputRef}
        type="radio"
        id={name + value}
        value={value}
        label={label}
        onChange={onChange}
        name={name}
        defaultChecked={defaultChecked}
      />
      <Toggle />
      <Label>{label}</Label>
    </Container>
  );
};

Radio.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]).isRequired,
  defaultChecked: PropTypes.bool.isRequired,
};

export default Radio;
