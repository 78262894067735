import { useRef } from 'react';

import FadeIn from 'react-fade-in';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import Button from '~/components/Button';
import Input from '~/components/Material/Input';
import AuthLayout from '~/layouts/Auth';
import yup from '~/libs/yup';
import api from '~/services/api';

import { Container, FormContainer, Title } from './styles';

const ForgotPassword = () => {
  const formRef = useRef(null);
  const history = useHistory();
  const { token } = useParams();

  // const getIllustration = () => {
  //   const number = Math.floor(Math.random() * 1);
  //   const Illustrations = [<SVG1 />];

  //   return Illustrations[number];
  // };

  const validate = async (data) => {
    try {
      const schema = yup.object().shape({
        password: yup.string().min(6).required().label('Senha'),
        password_confirmation: yup
          .string()
          .oneOf([yup.ref('password'), null], 'As senhas não coincidem'),
      });

      const result = await schema.validate(data, {
        abortEarly: false,
        stripUnknown: true,
      });

      return result;
    } catch (err) {
      const validationErrors = {};
      if (err) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }

      return false;
    }
  };

  const handleSubmit = async (formData) => {
    try {
      const data = await validate(formData);

      if (data) {
        const res = await api.post('/reset-password', {
          token,
          ...data,
        });

        if (res.status === 200) history.push('/login');
      }
    } catch (err) {
      toast.error('Error!');
    }
  };

  return (
    <AuthLayout>
      <Container>
        <FormContainer ref={formRef} onSubmit={handleSubmit}>
          <FadeIn>
            <Title>Recuperar senha</Title>
            <Input type="password" name="password" label="Senha" />
            <Input
              type="password"
              name="password_confirmation"
              label="Confirme a senha"
            />
            <Button type="submit" label="Atualizar senha" />
          </FadeIn>
        </FormContainer>
      </Container>
    </AuthLayout>
  );
};

export default ForgotPassword;
