import yup from '~/libs/yup';

export const storeSchema = yup.object().shape({
  name: yup.string().required().label('Nome'),
  courtesy_type: yup.string().nullable().required().label('Tipo cortesia'),
  person_id: yup.string().required().label('Pessoa'),
  type_lodging_id: yup.string().required().label('tipo'),
  commission: yup.string().required().label('Comissão'),
  checkin_time: yup.string().required().label('CheckIn'),
  checkout_time: yup.string().required().label('CheckOut'),
});
