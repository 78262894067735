import { useContext, useEffect, useRef } from 'react';

import { Form } from '@unform/web';
import PropTypes from 'prop-types';
import * as FaIcons from 'react-icons/fa';
import { FiTrash2 } from 'react-icons/fi';
import { toast } from 'react-toastify';

import Input from '~/components/Material/Input';
import RadioGroup from '~/components/RadioGroup';
import api from '~/services/api';

import DetailsContext from '../../../DetailsContext';
import { Container, ButtonDelete, IconBox, Name } from './styles';

const Item = ({ data }) => {
  const formRef = useRef();
  const { updatePivotDetail, removeDetailFromLodging } = useContext(
    DetailsContext
  );

  const { name, is_boolean, icon_name, lodging } = data;
  const { id, lodging_id, detail_id, value } = lodging;

  useEffect(() => {
    formRef.current.setFieldValue(`detail_${detail_id}`, value);
  }, [detail_id, value]);

  const createDetail = async (formData) => {
    try {
      const response = await api.post(`/lodgings/${lodging_id}/details`, {
        detail_id,
        ...formData,
      });

      if (response.status === 201) {
        // Formatando os dados

        toast.success('Detalhe criado com sucesso!');
        updatePivotDetail(detail_id, response.data.id);
      }
    } catch (error) {
      toast.error('Erro ao criar o detalhe...');
    }
  };

  const updateDetail = async (formData) => {
    try {
      const response = await api.put(`/lodgings/${lodging_id}/details/${id}`, {
        detail_id,
        ...formData,
      });

      if (response.status === 200) {
        // Formatando os dados

        toast.success('Detalhe atualizado com sucesso!');
      }
    } catch (error) {
      toast.error('Erro ao atualizar o detalhe...');
    }
  };

  const handleChangeRadio = (radio) => {
    if (id === 0) {
      createDetail(radio);
    } else {
      updateDetail(radio);
    }
  };

  const handleBlurInput = (event) => {
    const inputValue = event.target.value;

    if (id === 0) {
      createDetail({ value: inputValue });
    } else {
      updateDetail({ value: inputValue });
    }
  };

  const handleClickRemoveDetail = async () => {
    try {
      const response = await api.delete(
        `/lodgings/${lodging_id}/details/${id}`
      );

      if (response.status === 200) {
        // Formatando os dados

        toast.success('Detalhe deletado com sucesso!');

        removeDetailFromLodging(detail_id);
      }
    } catch (error) {
      toast.error('Erro ao deletar o detalhe...');
    }
  };

  const Icon = FaIcons[icon_name];

  return (
    <Container>
      <IconBox>
        <Icon />
      </IconBox>
      <Name>{name}</Name>
      <Form ref={formRef}>
        {is_boolean ? (
          <RadioGroup
            direction="row"
            name={`detail_${detail_id}`}
            onChange={handleChangeRadio}
            items={[
              {
                label: 'sim',
                value: 'true',
              },
              {
                label: 'não',
                value: 'false',
              },
            ]}
          />
        ) : (
          <Input name={`detail_${detail_id}`} onBlur={handleBlurInput} />
        )}
      </Form>
      <ButtonDelete type="button" onClick={handleClickRemoveDetail}>
        <FiTrash2 />
      </ButtonDelete>
    </Container>
  );
};

Item.propTypes = {
  data: PropTypes.shape({
    name: PropTypes.string.isRequired,
    is_boolean: PropTypes.bool.isRequired,
    icon_name: PropTypes.string.isRequired,
    lodging: PropTypes.shape({
      id: PropTypes.number.isRequired,
      lodging_id: PropTypes.number.isRequired,
      detail_id: PropTypes.number.isRequired,
      value: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default Item;
