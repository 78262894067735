import PropTypes from 'prop-types';
import { MdRemoveRedEye } from 'react-icons/md';

import { Container } from './styles';

const ViewButton = ({ onClick }) => {
  return (
    <Container type="button" onClick={onClick}>
      <MdRemoveRedEye />
    </Container>
  );
};

ViewButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default ViewButton;
