import PropTypes from 'prop-types';

import Item from './Item';
import { Container } from './styles';

const List = ({ data }) => {
  return (
    <Container>
      {data.map(
        (detail) => detail.lodging && <Item key={detail.id} data={detail} />
      )}
    </Container>
  );
};

List.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default List;
