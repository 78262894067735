import { Form } from '@unform/web';
import PropTypes from 'prop-types';
import { IoIosClose } from 'react-icons/io';

import { Container, CloseButton, Backdrop } from './styles';

const SideForm = ({ children, formRef, data, onClose, onSubmit }) => {
  const hasData = Object.entries(data).length !== 0;

  return (
    <>
      <Container open={hasData}>
        <CloseButton type="button" onClick={onClose}>
          <IoIosClose />
        </CloseButton>
        <Form ref={formRef} initialData={data} onSubmit={onSubmit}>
          {hasData && children}
        </Form>
      </Container>
      <Backdrop open={hasData} onClick={onClose} />
    </>
  );
};

SideForm.defaultProps = {
  data: {},
};

SideForm.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]),
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.element,
    PropTypes.array,
  ]).isRequired,
  formRef: PropTypes.oneOfType([PropTypes.object]).isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default SideForm;
