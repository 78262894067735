import { useCallback } from 'react';

export const useImageReader = () => {
  const readFile = useCallback((file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = () => {
        resolve(reader.result);
      };

      reader.onerror = reject;

      reader.readAsDataURL(file);
    });
  }, []);

  const isPortrait = useCallback((source) => {
    return new Promise((resolve, reject) => {
      const image = new Image();

      image.onload = () => {
        resolve(image.width < image.height);
      };

      image.onerror = reject;

      image.src = source;
    });
  }, []);

  const loadImage = useCallback(
    async (file) => {
      try {
        const src = await readFile(file);
        const portrait = await isPortrait(src);

        const name = file.name.split('.').slice(0, -1).join('.');

        return {
          src,
          isPortrait: portrait,
          description: '',
          name,
          file,
        };
      } catch (error) {
        return {};
      }
    },
    [readFile, isPortrait]
  );

  return { loadImage };
};

export default useImageReader;
