import { createActions, createReducer } from 'reduxsauce';

export const { Types, Creators } = createActions({
  setDarkTheme: [''],
  setLightTheme: [''],
});

const INITIAL_STATE = 'light';

const setDarkTheme = () => 'dark';

const setLightTheme = () => 'light';

export default createReducer(INITIAL_STATE, {
  [Types.SET_DARK_THEME]: setDarkTheme,
  [Types.SET_LIGHT_THEME]: setLightTheme,
});
