import {
  forwardRef,
  useContext,
  useImperativeHandle,
  useState,
  useRef,
} from 'react';

import { useParams } from 'react-router-dom';

import Input from '~/components/Material/Input';
import ModalDeleteContext from '~/components/ModalDelete/ModalContext';
import SideForm from '~/components/SideForm';
import DangerZone from '~/components/SideForm/DangerZone';
import FixedControl from '~/components/SideForm/FixedControl';
import Section from '~/components/SideForm/Section';
import Title from '~/components/SideForm/Title';
import api from '~/services/api';
import yupValidate from '~/utils/yupValidate';
import { createSchema, updateSchema } from '~/validators/tariffType.schemas';

import { Content } from './styles';

const Form = ({ ...rest }, ref) => {
  const { id } = useParams();

  const { openModalDelete } = useContext(ModalDeleteContext);

  const formRef = useRef();
  const [tariffType, setTariffType] = useState({});

  useImperativeHandle(
    ref,
    () => ({
      setData: (value) => setTariffType(value),
    }),
    []
  );

  const closeForm = (reload) => {
    setTariffType({});
    formRef.current.reset();
    rest.onClose(reload);
  };

  const storeTariffType = async (data) => {
    try {
      // Dados básicos
      const response = await api.post(
        `/lodgings/${id}/tariff-types/${tariffType.id}`,
        data
      );

      if (response.status === 201) {
        closeForm(true);
      }
      return 'response';
    } catch (error) {
      if (error.response) {
        const { status } = error.response;

        if (status === 400) {
          return error;
        }
      }

      return error;
    }
  };

  const updateTariffType = async (data) => {
    try {
      // Dados básicos
      const response = await api.put(
        `/lodgings/${id}/tariff-types/${tariffType.id}`,
        data
      );

      if (response.status === 200) {
        closeForm(true);
      }
      return 'response';
    } catch (error) {
      if (error.response) {
        const { status } = error.response;

        if (status === 400) {
          return error;
        }
      }
      return error;
    }
  };

  const handleSubmit = async (formData) => {
    // Limpa os erros
    formRef.current.setErrors({});

    const validationSchema = tariffType.id ? updateSchema : createSchema;
    // Valida os campos
    const { success, data, errors } = await yupValidate(validationSchema, {
      ...formData,
    });

    if (!success) {
      return formRef.current.setErrors(errors);
    }

    if (tariffType.id) {
      return updateTariffType(data);
    }

    return storeTariffType(data);
  };

  const handleOnClose = () => closeForm(false);

  const handleDelete = () => {
    openModalDelete({
      url: `/lodgings/${id}/tariff-types/${tariffType.id}`,
      title: 'Deseja deletar o tipo tarifário?',
      onDelete: () => closeForm(true),
      autoClose: true,
      messages: {
        success: 'Sucesso ao deletar o tipo tarifário!',
        error: 'Erro ao deletar o tipo tarifário...',
      },
    });
  };

  return (
    <SideForm
      formRef={formRef}
      data={tariffType}
      onClose={handleOnClose}
      onSubmit={handleSubmit}
    >
      <Content>
        <Title
          label={
            tariffType.id
              ? `Editando tipo tarifário - ${tariffType.id}`
              : 'Novo tipo tarifário'
          }
        />
        <Section>
          <Input label="Nome" name="description" />
          <Input
            label="Quantidade"
            name="quantity"
            mask="currency"
            returnUnmasked
          />
        </Section>
        {tariffType.id && (
          <DangerZone message="Deletar tipo tarifário" onClick={handleDelete} />
        )}
        <FixedControl />
      </Content>
    </SideForm>
  );
};

export default forwardRef(Form);
