import styled from 'styled-components';

import { Form } from '@unform/web';
import { Link } from 'react-router-dom';

export const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f5f5f5;
`;

export const FormContainer = styled(Form)`
  z-index: 2;
  background-color: #ffffff;
  border-radius: 8px;
  padding: 60px 60px 50px 60px;
  width: 100%;
  max-width: 440px;
  box-shadow: 0 4px 8px rgba(154, 160, 185, 0.05),
    0 7px 20px rgba(166, 173, 201, 0.2);

  .material-input {
    margin-bottom: 30px;
  }

  .root-button {
    width: 100%;
  }
`;

export const Title = styled.h1`
  color: #1c1c1e;
  font-size: 32px;
  font-weight: 600;
  margin-bottom: 30px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;

  margin-top: 10px;
  width: 100%;
`;

export const ButtonText = styled(Link)`
  border: none;
  background-color: transparent;

  padding: 10px;
  font-size: 14px;
  color: #48484a;

  transition: text-decoration 200ms linear;

  &:hover {
    text-decoration: underline;
  }
`;
