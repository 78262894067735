import PropTypes from 'prop-types';

import { Container, Content } from './styles';

const CircleButton = ({
  colorIcon,
  color,
  icon,
  tooltip,
  disabled,
  onClick,
}) => {
  return (
    <Container>
      <Content
        type="button"
        tooltip={tooltip}
        color={color}
        colorIcon={colorIcon}
        onClick={onClick}
        disabled={disabled}
      >
        {icon}
      </Content>
    </Container>
  );
};

CircleButton.defaultProps = {
  color: 'default',
  colorIcon: '',
  onClick: () => {},
  disabled: false,
};

CircleButton.propTypes = {
  color: PropTypes.string,
  colorIcon: PropTypes.string,
  icon: PropTypes.element.isRequired,
  tooltip: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

export default CircleButton;
