import { useState, useEffect } from 'react';

import PropTypes from 'prop-types';
import { useLocation, useParams } from 'react-router-dom';

import api from '~/services/api';

import { Container, Title, Total } from './styles';

const Header = ({ total }) => {
  // Lodging
  const { id } = useParams();
  const { state } = useLocation();

  const [title, setTitle] = useState('');

  useEffect(() => {
    const getLodging = async () => {
      const { data, status } = await api.get(`/lodgings/${id}`);

      if (status === 200) {
        setTitle(data.person.name);
      }
    };

    if (!state || state.id !== id) {
      getLodging();
    } else {
      setTitle(state.person.name);
    }
  }, [state, id]);

  return (
    <Container>
      <Title>{title} - Detalhes</Title>
      <Total>{total} registros</Total>
    </Container>
  );
};

Header.propTypes = {
  total: PropTypes.number.isRequired,
};

export default Header;
