import styled from 'styled-components';

import devices from '~/styles/configs/devices';

export const Container = styled.tbody`
  color: #3a3a3c;

  tr {
    background-color: #fafafa;

    @media ${devices.laptop} {
      td {
        &:first-child {
          border-radius: 4px 0 0 4px;
        }

        &:last-child {
          border-radius: 0 4px 4px 0;
        }
      }
    }
  }
`;
