import { forwardRef, useImperativeHandle, useState, useRef } from 'react';

import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import Select from '~/components/Material/Select';
import SideForm from '~/components/SideForm';
import FixedControl from '~/components/SideForm/FixedControl';
import Section from '~/components/SideForm/Section';
import Title from '~/components/SideForm/Title';
import useWebService from '~/hooks/useWebService';

import { Content } from './styles';

const wsStatus = [
  { label: 'Aberto', value: 'Aberto' },
  { label: 'Ganho', value: 'Ganho' },
  { label: 'Perdido', value: 'Perdido' },
];

const StatusForm = ({ ...rest }, ref) => {
  const formRef = useRef();
  const buttonRef = useRef();
  const [data, setData] = useState({});
  const [atendimentoId, setAtendimentoId] = useState(null);
  const user = useSelector((state) => state.user);
  const ws = useWebService();

  useImperativeHandle(
    ref,
    () => ({
      setData: (value) => {
        setData(value);
        setAtendimentoId(value.atendimento_id);
      },
    }),
    []
  );

  const closeForm = (shouldReload) => {
    formRef.current.reset();
    formRef.current.setErrors({});
    setData({});
    rest.onClose(shouldReload);
  };

  const handleSubmit = async (formData) => {
    try {
      buttonRef.current.start();

      const response = await ws.post(
        'atendimento/alterarAtendimentoStatus.php',
        {
          ...formData,
          atendente_id: user.id,
          atendente_nome: user.name,
          atendimento_id: atendimentoId,
        }
      );

      if (response.data.result) {
        buttonRef.current.finish({
          type: 'success',
          callback: () => closeForm(true),
        });
      } else {
        toast.error(`Erro ao alterar status do negocio...`);
        buttonRef.current.finish({
          type: 'error',
          autoReset: true,
          timeReset: 2,
        });
      }

      return 'response';
    } catch (error) {
      buttonRef.current.finish({
        type: 'error',
        autoReset: true,
        timeReset: 2,
      });
      return error;
    }
  };

  const handleOnClose = () => closeForm(false);

  return (
    <SideForm
      formRef={formRef}
      data={data}
      onClose={handleOnClose}
      onSubmit={handleSubmit}
    >
      <Content>
        <Title label="Alterar status do Negócio" />
        <Section>
          <Select label="Status" name="status" items={wsStatus} />
        </Section>

        <FixedControl buttonRef={buttonRef} buttonLabel="Salvar" />
      </Content>
    </SideForm>
  );
};

export default forwardRef(StatusForm);
