import { BrowserRouter, Switch } from 'react-router-dom';

import ConfigDetail from '~/pages/ConfigDetail';
import ForgotPassword from '~/pages/ForgotPassword';
import Lead from '~/pages/Lead';
import LeadDetail from '~/pages/LeadDetail';
// import LeadInterest from '~/pages/LeadInterest';
import Lodging from '~/pages/Lodging';
import LodgingDetail from '~/pages/LodgingDetail';
import LodgingImage from '~/pages/LodgingImage';
import LodgingPensionType from '~/pages/LodgingPensionType';
import LodgingTariffType from '~/pages/LodgingTariffType';
import Login from '~/pages/Login';
import NotFound from '~/pages/NotFound';
import Person from '~/pages/Person';
import RecoverPassword from '~/pages/RecoverPassword';
import Settings from '~/pages/Settings';
import User from '~/pages/User';
import Welcome from '~/pages/Welcome';

import LoginRoute from './LoginRoute';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';

const Routes = () => {
  return (
    <BrowserRouter>
      <Switch>
        <PrivateRoute path="/" exact component={Welcome} />

        <LoginRoute path="/login" component={Login} />

        <PublicRoute path="/esqueci-minha-senha" component={ForgotPassword} />

        <PublicRoute
          path="/recuperar-senha/:token"
          component={RecoverPassword}
        />

        <PrivateRoute path="/configuracoes" component={Settings} exact />

        <PrivateRoute
          path="/configuracoes/hospedagens/detalhes"
          component={ConfigDetail}
        />

        <PrivateRoute path="/atendimento/leads" component={Lead} exact />
        {/* <PrivateRoute
          path="/atendimento/leads/:id(\d+)/interesses"
          component={LeadInterest}
        /> */}
        <PrivateRoute
          path="/atendimento/leads/:id(\d+)/detalhes/:businessId(\d+)"
          component={LeadDetail}
        />

        <PrivateRoute path="/hospedagens" component={Lodging.List} exact />

        <PrivateRoute
          path="/hospedagens/:id(\d+)"
          component={Lodging.View}
          exact
        />

        <PrivateRoute
          path="/hospedagens/:id(\d+)/detalhes"
          component={LodgingDetail}
        />

        <PrivateRoute
          path="/hospedagens/:id(\d+)/imagens"
          component={LodgingImage}
        />

        <PrivateRoute
          path="/hospedagens/:id(\d+)/tipo-pensao"
          component={LodgingPensionType}
        />

        <PrivateRoute
          path="/hospedagens/:id(\d+)/tipo-tarifario"
          component={LodgingTariffType}
        />

        <PrivateRoute path="/pessoas" component={Person} />

        <PrivateRoute path="/usuarios" component={User} />

        {/* <PrivateRoute path="/usuarios" component={User.List} exact /> */}
        {/* <PrivateRoute path="/usuarios/cadastro" component={User.Store} /> */}

        <PublicRoute path="*" component={NotFound} />
      </Switch>
    </BrowserRouter>
  );
};

export default Routes;
