import PropTypes from 'prop-types';

import { Container, Item } from './styles';

const Link = ({ to, icon, label, active, disabled }) => {
  return (
    <Container to={to} active={active} disabled={disabled}>
      <Item>
        {icon} {label}
      </Item>
    </Container>
  );
};

Link.defaultProps = {
  active: '',
  disabled: false,
  icon: <span />,
};

Link.propTypes = {
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  label: PropTypes.string.isRequired,
  active: PropTypes.string,
  disabled: PropTypes.bool,
  icon: PropTypes.element,
};

export default Link;
