import Logo from './Logo';
import Logout from './Logout';
import Navigation from './Navigation';
import { Container, LeftContent, RightContent, Profile } from './styles';
import User from './User';

const TopMenu = () => {
  return (
    <Container>
      <LeftContent>
        <Logo />
        <Navigation />
      </LeftContent>
      <RightContent>
        <Profile>
          <User />
          <Logout />
        </Profile>
      </RightContent>
    </Container>
  );
};

export default TopMenu;
