import styled, { css } from 'styled-components';

export const Container = styled.div`
  position: absolute;

  display: flex;

  ${({ isPortrait }) => {
    if (isPortrait)
      return css`
        flex-direction: column;
        padding: 2px 0;
      `;

    return css`
      flex-direction: row;
      padding: 0 2px;
    `;
  }}

  top: 15px;
  left: 15px;

  border-radius: 30px;

  background-color: #ffffff;
`;
