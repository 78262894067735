import styled from 'styled-components';

export const Content = styled.div`
  .root-input,
  .root-checkbox,
  .root-radio,
  .root-autocomplete,
  .root-select {
    margin-bottom: 16px;
  }
`;
