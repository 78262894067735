import { forwardRef, useImperativeHandle, useState, useRef } from 'react';

import { addDays, isAfter } from 'date-fns';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import DatePicker from '~/components/Material/DatePicker';
import Input from '~/components/Material/Input';
import SideForm from '~/components/SideForm';
import FixedControl from '~/components/SideForm/FixedControl';
import Section from '~/components/SideForm/Section';
import Title from '~/components/SideForm/Title';
import useWebService from '~/hooks/useWebService';
import yupValidate from '~/utils/yupValidate';
import { createSchema } from '~/validators/attendanceInterest.schemas';

import { Content } from './styles';

const InterestForm = ({ ...rest }, ref) => {
  const formRef = useRef();
  const buttonRef = useRef();
  const [data, setData] = useState({});
  const [atendimentoId, setAtendimentoId] = useState(null);
  const [checkoutMinDate, setCheckoutMinDate] = useState(new Date());
  const user = useSelector((state) => state.user);
  const ws = useWebService();

  useImperativeHandle(
    ref,
    () => ({
      setData: (value) => {
        setData(value);
        setAtendimentoId(value.atendimento_id);
      },
    }),
    []
  );

  const closeForm = (shouldReload) => {
    formRef.current.reset();
    formRef.current.setErrors({});
    setData({});
    rest.onClose(shouldReload);
  };

  const toastError = (message) => {
    toast.error(message);
    buttonRef.current.finish({
      type: 'error',
      autoReset: true,
      timeReset: 2,
    });
  };

  const handleSubmit = async (formData) => {
    try {
      buttonRef.current.start();

      // Valida os campos
      const { success, data: validData, errors } = await yupValidate(
        createSchema,
        {
          ...formData,
        }
      );

      if (!success) {
        toastError('Dados inválidos...');
        return formRef.current.setErrors(errors);
      }

      const response = await ws.post('atendimento/insereInteresse.php', {
        ...validData,
        atendente_id: user.id,
        atendente_nome: user.name,
        atendimento_id: atendimentoId,
      });

      if (response.data.result) {
        buttonRef.current.finish({
          type: 'success',
          callback: () => closeForm(true),
        });
      } else {
        toastError('Erro na API ao inserir interesse...');
      }

      return 'response';
    } catch (error) {
      toastError('Erro ao inserir interesse...');
      return error;
    }
  };

  const handleCheckinChange = (checkinDate) => {
    const checkoutDate = formRef.current.getFieldValue('checkout');
    const outMinDate = addDays(checkinDate, 1);

    if (isAfter(outMinDate, checkoutDate)) {
      formRef.current.setFieldValue('checkout', outMinDate);
    }

    setCheckoutMinDate(outMinDate);
  };

  const handleOnClose = () => closeForm(false);

  return (
    <SideForm
      formRef={formRef}
      data={data}
      onClose={handleOnClose}
      onSubmit={handleSubmit}
    >
      <Content>
        <Title label="Novo Interesse" />
        <Section>
          <Input label="Hospedagem" name="hospedagem" />
          <DatePicker
            label="CheckIn"
            name="checkin"
            minDate={addDays(new Date(), 1)}
            onChange={handleCheckinChange}
            disableText
          />
          <DatePicker
            label="CheckOut"
            name="checkout"
            minDate={checkoutMinDate}
            disableText
          />
          <Input label="Adultos" name="adultos" type="number" />
          <Input label="Crianças" name="criancas" type="number" />
          <Input label="Infantil" name="infantil" type="number" />
          <Input
            label="Mensagem"
            name="mensagem"
            multiline
            rows={6}
            rowsMax={6}
          />
        </Section>

        <FixedControl buttonRef={buttonRef} buttonLabel="Salvar" />
      </Content>
    </SideForm>
  );
};

export default forwardRef(InterestForm);
