import styled from 'styled-components';

export const Container = styled.div`
  .rc-pagination {
    display: flex;
    align-items: center;
    justify-content: ${({ position }) => position};

    li {
      color: #48484a;

      &.rc-pagination-disabled + li {
        color: #007aff;
      }

      &.rc-pagination-item-active {
        color: #007aff;
      }

      > {
        svg {
          width: 20px;
          height: 20px;
        }

        * {
          cursor: pointer;

          display: flex;
          align-items: center;
          justify-content: center;

          min-width: 30px;
          height: 30px;
        }
      }
    }
  }
`;
