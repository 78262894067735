import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  padding: 30px 60px 60px 60px;
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 30px;

  border-radius: 8px;
  border: 1px solid #eeeeee;
`;

export const Label = styled.div`
  font-size: 16px;
  color: #8e8e93;
`;
