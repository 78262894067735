import styled from 'styled-components';

export const Content = styled.div`
  .root-input,
  .root-select {
    margin-bottom: 16px;
  }
`;

export const HelpText = styled.div`
  font-size: 12px;
  color: #8e8e93;

  margin-bottom: 16px;
`;
