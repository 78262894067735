import { useCallback, useEffect, useRef } from 'react';

import { useField } from '@unform/core';
import PropTypes from 'prop-types';

import masks from '~/utils/masks';

import { Container, Field, ErrorMessage } from './styles';

const Input = ({
  label,
  name,
  mask,
  fullWidth,
  type,
  onBlur,
  onChange,
  returnUnmasked,
  ...rest
}) => {
  const inputRef = useRef(null);
  const fieldRef = useRef(null);

  // defaultValue
  const { fieldName, registerField, error, defaultValue = '' } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
      getValue: (ref) => {
        const value = ref.value || '';
        if (mask && returnUnmasked) {
          return masks.unmask(value);
        }
        return value;
      },
      setValue: (ref, value) => {
        if (mask) {
          if (!masks[mask]) throw new Error('Máscara não definida');

          ref.value = masks[mask](value);
        } else {
          ref.value = value;
        }
      },
    });
  }, [fieldName, registerField, mask, returnUnmasked]);

  useEffect(() => {
    if (inputRef.current && defaultValue) {
      // Levanta a label
      fieldRef.current.firstChild.classList.add('MuiInputLabel-shrink');
      fieldRef.current.firstChild.dataset.shrink = true;

      if (mask) {
        if (!masks[mask]) throw new Error('Máscara não definida');
        inputRef.current.value = masks[mask](defaultValue);
      } else {
        inputRef.current.value = defaultValue;
      }
    }
  }, [defaultValue]);

  // To use masks
  const handleChange = useCallback(
    (e) => {
      e.persist();

      if (mask) {
        if (!masks[mask]) throw new Error('Máscara não definida');

        const { value } = e.target;
        inputRef.current.value = masks[mask](value);
      }

      if (onChange) onChange(e);
    },
    [inputRef, mask]
  );

  return (
    <Container className="root-input" fullWidth={fullWidth}>
      <Field
        ref={fieldRef}
        inputRef={inputRef}
        label={label}
        type={type}
        name={fieldName}
        onChange={handleChange}
        error={!!error}
        fullWidth={fullWidth}
        defaultValue={defaultValue}
        aria-describedby={fieldName}
        onBlur={onBlur}
        {...rest}
      />
      <ErrorMessage id={fieldName}>{error}</ErrorMessage>
    </Container>
  );
};

Input.defaultProps = {
  type: 'text',
  label: '',
  mask: '',
  fullWidth: true,
  returnUnmasked: false,
  onBlur: () => {},
  onChange: () => {},
};

Input.propTypes = {
  type: PropTypes.string,
  label: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  name: PropTypes.string.isRequired,
  fullWidth: PropTypes.bool,
  mask: PropTypes.string,
  returnUnmasked: PropTypes.bool,
};

export default Input;
