import { IoIosCamera } from 'react-icons/io';
import { useSelector } from 'react-redux';

import { Container, Name, Avatar } from './styles';

const User = () => {
  const { avatar, name } = useSelector((state) => state.user);

  return (
    <Container>
      <Avatar hasImage={!!avatar}>
        <IoIosCamera />
      </Avatar>
      <Name>{name}</Name>
    </Container>
  );
};

export default User;
