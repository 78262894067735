import styled from 'styled-components';

export const Container = styled.button`
  position: relative;

  display: flex;
  align-items: center;

  width: 100%;
  padding: 15px;
  margin-bottom: 7px;
  border-radius: 4px;

  border: 1px solid #fafafa;
  background-color: #fafafa;

  color: #48484a;

  transition: color 200ms linear;

  svg {
    color: #48484a;

    transition: color 200ms linear;
  }

  &:after {
    content: 'adicionar';

    position: absolute;

    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: 4px;

    top: 0;
    left: 0;

    color: #007aff;
    border: 1px solid #007aff;
    background-color: #007aff30;

    height: 100%;
    width: 100%;

    opacity: 0;

    transition: opacity 200ms linear;
  }

  :hover {
    color: #e0e0e0;

    svg {
      color: #e0e0e0;
    }

    &:after {
      opacity: 1;
    }
  }
`;

export const IconBox = styled.div`
  display: flex;
  align-items: center;

  margin-right: 7px;

  svg {
    pointer-events: none;
  }
`;

export const Label = styled.div``;
