import styled from 'styled-components';

export const Container = styled.div`
  grid-area: side_menu;
  background-color: #fafafa;

  width: 100%;
  height: 100%;

  padding: 30px;
`;

export const Title = styled.div`
  color: #636366;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 30px;
`;

export const ListLinks = styled.div``;
