import PropTypes from 'prop-types';

import { Container } from './styles';

const Actions = ({ children, align }) => {
  return <Container align={align}>{children}</Container>;
};

Actions.defaultProps = {
  align: 'space-between',
};

Actions.propTypes = {
  align: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.object,
    PropTypes.array,
  ]).isRequired,
};

export default Actions;
